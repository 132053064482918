import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">

<path d="M0 6400 l0 -6400 6400 0 6400 0 0 6400 0 6400 -6400 0 -6400 0 0
-6400z m6792 6300 c998 -67 1911 -342 2768 -835 166 -95 481 -303 620 -408
258 -196 431 -347 681 -596 249 -250 416 -441 603 -691 30 -41 59 -79 64 -85
4 -5 41 -59 83 -120 488 -714 826 -1523 989 -2366 85 -442 116 -823 107 -1319
-7 -406 -24 -595 -87 -965 -165 -970 -570 -1906 -1174 -2710 -79 -106 -267
-335 -356 -435 -104 -117 -408 -415 -538 -528 -907 -791 -2034 -1312 -3217
-1486 -521 -77 -1116 -88 -1635 -30 -761 84 -1493 304 -2165 649 -88 45 -177
91 -198 103 -21 12 -68 39 -105 60 -415 235 -859 575 -1241 947 -918 897
-1537 2037 -1781 3284 -216 1104 -142 2212 217 3271 380 1123 1095 2139 2030
2887 567 453 1191 799 1864 1034 446 155 836 243 1399 315 240 30 788 43 1072
24z"/>
<path d="M1475 9315 c-91 -48 -141 -111 -174 -221 -18 -62 -21 -97 -21 -266 0
-154 -3 -202 -15 -224 -21 -41 -21 -307 0 -348 21 -40 21 -253 0 -280 -22 -29
-21 -309 0 -350 19 -36 19 -75 0 -100 -22 -29 -21 -309 0 -350 13 -25 15 -241
15 -1743 0 -1554 2 -1720 16 -1771 36 -124 100 -206 190 -242 64 -27 58 -27
419 25 61 8 142 20 180 25 39 5 120 17 180 26 61 9 139 20 175 24 77 11 231
32 390 55 63 9 142 20 175 25 231 31 306 42 350 55 67 19 142 87 183 165 66
125 62 -50 62 2551 0 1568 -3 2384 -10 2420 -23 121 -106 244 -194 288 -26 12
-77 27 -114 31 -115 15 -293 39 -402 56 -58 8 -139 19 -180 24 -41 5 -124 16
-185 25 -60 8 -141 19 -180 25 -38 5 -119 16 -180 25 -60 8 -144 19 -185 25
-41 5 -124 17 -185 25 -60 9 -144 19 -185 21 -67 4 -81 2 -125 -21z m250 -135
c172 -20 162 -16 179 -68 28 -84 81 -124 176 -136 30 -4 89 -11 130 -16 124
-15 568 -69 665 -80 49 -6 101 -8 116 -5 33 8 63 39 84 89 l16 39 57 -7 c177
-20 213 -28 254 -60 25 -18 54 -55 72 -91 l31 -60 3 -2380 c2 -1644 -1 -2395
-8 -2430 -14 -63 -63 -140 -109 -171 -38 -24 -71 -30 -746 -120 -104 -13 -219
-29 -255 -34 -382 -56 -692 -93 -770 -94 -84 -1 -99 2 -127 22 -18 13 -45 44
-60 70 l-28 47 -2 2660 -2 2660 22 54 c13 33 36 65 58 83 34 27 107 49 139 42
8 -2 56 -8 105 -14z"/>
<path d="M2515 3834 c-452 -57 -429 -53 -433 -87 -3 -25 0 -27 30 -27 18 0
132 13 253 29 121 16 279 37 350 45 183 23 192 25 199 41 9 26 -5 45 -33 44
-14 -1 -179 -21 -366 -45z"/>
<path d="M4964 9171 c-17 -4 -44 -18 -62 -31 l-31 -23 -57 18 c-61 19 -181 18
-219 -2 -11 -6 -42 -22 -69 -34 -31 -15 -94 -67 -164 -134 -63 -60 -120 -111
-127 -113 -7 -2 -15 -11 -18 -21 -5 -20 -26 -18 -122 9 -22 6 -61 17 -87 23
-25 7 -50 15 -54 20 -21 21 -137 -4 -175 -39 -17 -15 -26 -16 -67 -6 -26 6
-51 7 -55 2 -18 -17 -26 -356 -26 -1035 0 -829 8 -1091 32 -1128 13 -20 77
-59 177 -107 67 -33 214 -54 318 -47 46 4 91 2 100 -3 30 -16 86 -39 140 -56
l53 -17 -40 -24 c-57 -34 -102 -84 -169 -188 -70 -108 -99 -174 -124 -280 -18
-72 -20 -131 -25 -580 -6 -573 1 -715 40 -790 13 -28 28 -75 32 -105 11 -84
39 -125 111 -162 16 -8 103 -55 194 -103 364 -193 384 -200 535 -192 124 6
187 28 420 144 213 106 279 146 330 197 23 23 79 78 126 124 l86 83 15 -31 c8
-17 17 -30 19 -28 2 1 24 -12 49 -30 25 -18 72 -52 105 -76 33 -23 80 -52 105
-63 55 -25 179 -63 205 -63 28 0 107 -30 130 -50 11 -9 47 -27 80 -40 33 -13
67 -29 75 -36 8 -6 70 -35 138 -63 132 -55 238 -77 329 -68 44 5 103 27 123
47 16 16 272 182 310 200 25 13 56 31 69 41 36 29 54 23 93 -30 72 -97 178
-158 283 -164 23 -1 65 -16 97 -35 32 -17 64 -32 73 -32 8 0 43 -11 77 -24
136 -51 202 -66 284 -66 61 0 87 4 105 18 35 25 61 41 85 51 11 5 66 39 123
76 91 60 140 89 221 131 71 37 150 139 150 194 0 11 3 20 7 20 8 0 132 -45
168 -60 11 -5 60 -25 109 -45 91 -37 191 -85 261 -125 56 -32 129 -50 205 -50
74 0 112 8 104 21 -7 12 59 -13 166 -64 177 -82 235 -99 350 -101 116 -1 123
1 255 93 67 46 174 117 251 166 28 18 75 56 105 83 67 62 81 106 104 312 26
229 5 702 -40 920 -13 63 -27 140 -30 170 -7 62 -19 96 -82 229 -50 104 -47
99 -157 223 -74 83 -180 168 -210 168 -8 0 -19 7 -26 15 -7 8 -20 15 -29 15
-9 0 -25 7 -35 15 -11 8 -29 15 -40 15 -12 0 -30 7 -40 15 -11 8 -34 15 -50
15 -70 0 -101 24 -48 36 15 4 32 10 37 14 6 4 53 22 105 40 52 18 115 41 140
52 25 10 54 23 65 27 53 22 134 56 170 72 110 49 231 99 238 99 17 0 81 56
109 94 l28 39 0 189 c0 180 -1 190 -22 221 -26 36 -44 53 -73 68 -53 26 -115
69 -154 105 l-33 31 1 109 c3 233 13 403 24 412 7 5 39 19 73 31 88 32 140 89
165 177 26 93 26 339 1 399 -30 72 -75 115 -119 115 -12 0 -43 11 -69 25 -26
14 -57 25 -69 25 -11 0 -30 7 -41 15 -10 8 -27 15 -37 15 -9 0 -34 9 -54 20
-21 11 -43 20 -50 20 -6 0 -24 6 -40 14 -15 8 -55 23 -88 34 -112 38 -130 45
-147 53 -24 12 -145 11 -153 -1 -3 -5 -27 -10 -52 -10 -25 0 -63 -5 -84 -11
-33 -10 -48 -9 -104 10 -119 39 -269 22 -540 -59 -16 -5 -66 -20 -110 -33 -44
-13 -90 -29 -102 -36 -17 -8 -29 -9 -42 -1 -11 5 -27 10 -35 10 -8 0 -49 20
-89 45 -41 25 -79 45 -84 45 -6 0 -32 12 -59 26 -218 117 -212 115 -306 120
-59 3 -103 0 -135 -9 -108 -32 -146 -38 -154 -25 -4 6 -45 17 -91 24 -130 20
-190 5 -306 -73 -51 -35 -96 -63 -99 -63 -4 0 -14 -6 -22 -13 -26 -22 -134
-66 -218 -89 -59 -16 -109 -21 -208 -22 -116 -1 -135 -4 -175 -24 -49 -26
-118 -87 -128 -114 -6 -15 -14 -13 -62 17 -30 19 -62 35 -70 35 -12 0 -102 39
-162 71 -10 5 -31 9 -47 9 -16 0 -34 7 -41 15 -7 8 -24 15 -39 15 -15 0 -35 6
-46 14 -11 7 -42 17 -70 21 -27 4 -53 12 -57 18 -10 16 -208 14 -276 -3 -39
-9 -59 -10 -75 -2 -12 6 -65 14 -118 17 -105 7 -188 -7 -286 -50 -62 -28 -154
-90 -206 -139 -39 -37 -57 -39 -57 -8 0 29 -48 91 -88 112 -20 12 -55 34 -77
50 -22 16 -53 32 -70 36 -16 4 -36 10 -45 14 -57 28 -113 50 -125 50 -8 0 -15
5 -15 10 0 6 -7 10 -15 10 -13 0 -38 9 -120 45 -106 47 -175 71 -230 80 -22 3
-49 8 -60 10 -11 2 -34 0 -51 -4z m116 -85 c44 -14 156 -56 215 -81 17 -7 77
-32 135 -55 163 -66 221 -96 270 -140 l45 -40 4 -172 c2 -95 -2 -274 -8 -398
-18 -342 -31 -669 -38 -943 l-6 -248 -31 -24 c-37 -28 -127 -69 -173 -79 -18
-4 -35 -12 -38 -17 -4 -5 -19 -9 -36 -9 -21 0 -59 22 -132 75 -56 42 -164 119
-239 171 -76 52 -140 99 -143 103 -7 12 -15 124 -20 295 -5 164 -9 186 -32
186 -14 0 -15 -44 -13 -412 4 -589 6 -564 -27 -595 -33 -30 -98 -61 -208 -98
-44 -15 -84 -31 -90 -36 -24 -23 -113 2 -221 60 -270 149 -466 278 -484 320
-10 25 -10 84 -1 309 6 152 13 457 16 677 5 491 15 757 30 808 7 23 22 45 37
55 41 24 74 11 498 -192 41 -19 89 -45 105 -56 61 -40 49 -51 275 249 19 26
60 76 90 112 30 36 69 82 85 104 39 51 72 85 82 85 4 0 28 -6 53 -14z m4098
-88 c64 -33 143 -76 174 -95 31 -19 94 -53 140 -77 68 -35 87 -50 102 -81 20
-39 20 -39 -3 -104 -13 -37 -31 -84 -41 -106 -10 -22 -34 -81 -53 -131 -20
-50 -46 -115 -60 -145 -62 -141 -149 -363 -176 -449 -20 -65 -18 -72 50 -175
61 -92 244 -430 259 -478 20 -63 9 -80 -72 -117 -189 -86 -225 -100 -253 -100
-23 0 -233 85 -250 101 -5 5 -20 9 -32 9 -21 0 -31 -16 -74 -107 -27 -60 -55
-119 -62 -133 -13 -27 -29 -36 -177 -101 -153 -67 -159 -69 -189 -69 -25 0
-166 66 -306 145 -27 15 -79 44 -115 63 -188 103 -236 156 -200 220 5 9 21 45
35 79 50 124 112 257 251 536 62 125 115 241 119 257 6 26 -6 49 -87 172 -52
79 -140 218 -196 310 -90 147 -102 172 -102 212 0 41 3 48 32 66 18 11 46 22
63 26 16 3 57 18 90 33 118 54 136 56 191 29 82 -40 137 -69 174 -89 97 -55
121 -63 138 -53 9 6 38 64 64 129 76 191 100 213 293 265 117 32 141 28 273
-42z m1585 -54 c29 -8 60 -20 69 -25 10 -5 23 -9 29 -9 6 0 25 -6 42 -13 33
-14 61 -24 162 -59 148 -51 307 -117 317 -132 21 -31 27 -121 20 -264 l-8
-153 -34 -34 c-22 -21 -61 -44 -103 -59 -114 -41 -117 -44 -118 -134 -3 -141
-6 -236 -13 -393 l-7 -157 35 -18 c55 -29 185 -116 223 -149 l35 -31 -2 -119
c-2 -66 -3 -143 -4 -171 -2 -81 -19 -94 -281 -203 -55 -23 -113 -48 -130 -55
-16 -8 -52 -22 -80 -31 -27 -10 -54 -22 -60 -26 -5 -3 -46 -19 -90 -34 -44
-15 -116 -43 -160 -63 -66 -29 -92 -36 -150 -36 -64 -1 -78 3 -170 48 -332
162 -399 199 -426 236 -25 34 -33 116 -24 254 6 104 10 120 32 146 14 17 32
30 40 30 9 0 49 20 90 45 40 25 79 45 86 45 33 0 50 27 48 76 -1 27 6 155 15
284 19 276 15 405 -13 425 -10 8 -71 35 -137 61 -119 48 -152 70 -162 110 -3
12 -4 91 -2 177 3 139 5 159 24 184 22 29 97 63 229 102 39 12 124 37 190 57
159 47 304 84 335 84 14 0 32 5 40 10 21 14 54 12 113 -6z m-3871 -90 c115
-29 252 -77 343 -122 159 -77 290 -238 315 -389 6 -32 15 -83 20 -113 32 -185
39 -495 20 -855 -7 -121 -13 -246 -15 -278 -1 -31 -8 -69 -16 -83 -28 -53
-155 -105 -264 -108 -53 -1 -70 4 -143 42 -45 23 -89 42 -96 42 -16 0 -26 -30
-26 -85 -1 -47 -21 -109 -45 -135 -20 -22 -130 -81 -205 -109 -67 -26 -196
-29 -240 -6 -15 8 -86 55 -157 103 -70 49 -143 97 -161 106 -36 19 -79 53
-127 102 -51 51 -60 122 -61 479 0 171 3 339 7 375 5 36 13 110 19 164 6 55
15 107 20 117 5 9 14 54 19 100 10 85 23 124 89 258 53 109 189 254 297 318
146 87 272 111 407 77z m2690 -844 l43 -85 0 -110 c0 -117 -13 -168 -62 -247
-13 -21 -23 -44 -23 -53 0 -30 -29 -11 -72 47 -16 20 -28 39 -28 42 0 3 -20
32 -45 65 -32 42 -45 69 -45 91 0 58 92 281 141 344 26 33 25 33 91 -94z
m-452 -1143 c79 -31 122 -36 207 -22 35 5 55 4 65 -4 7 -6 22 -19 34 -29 12
-9 48 -41 80 -69 33 -29 96 -76 142 -103 67 -42 86 -59 102 -93 30 -66 20
-101 -50 -165 -14 -12 -38 -29 -54 -37 -26 -13 -33 -13 -60 1 -17 8 -47 19
-66 24 -19 4 -60 16 -90 25 -30 9 -73 20 -95 25 -143 32 -180 42 -196 50 -10
6 -32 10 -49 10 -17 0 -41 5 -53 12 -12 6 -42 15 -67 19 -25 4 -61 12 -80 19
-19 6 -48 16 -65 22 -16 6 -53 14 -81 18 -56 9 -97 26 -90 39 6 10 80 45 139
66 65 23 89 47 133 130 20 39 43 78 52 87 18 21 35 18 142 -25z m-3895 -11
c90 -68 184 -94 238 -64 12 6 35 15 52 19 16 4 55 17 85 28 84 32 100 29 168
-36 94 -91 263 -180 382 -203 70 -13 203 -13 217 1 8 8 33 0 89 -27 75 -36 84
-38 167 -37 97 2 138 14 288 88 103 50 169 109 189 171 13 39 12 39 110 14 45
-11 52 -11 253 19 26 4 47 4 47 -1 0 -4 5 -8 11 -8 5 0 27 -13 47 -29 20 -16
62 -42 92 -57 30 -16 76 -49 102 -75 55 -55 68 -100 44 -148 -9 -17 -14 -31
-11 -31 12 0 -62 -59 -120 -95 -33 -21 -77 -53 -98 -72 -43 -38 -57 -41 -82
-13 -31 35 -243 150 -276 150 -8 0 -25 8 -38 17 -38 26 -270 22 -376 -7 -44
-12 -94 -25 -110 -29 -17 -4 -40 -13 -52 -19 -12 -7 -33 -12 -48 -12 -14 0
-38 -6 -53 -14 -15 -7 -48 -16 -74 -19 -49 -7 -156 -58 -254 -123 -72 -47 -84
-59 -164 -156 -34 -43 -67 -78 -71 -78 -5 0 -24 24 -43 53 -18 28 -42 59 -52
69 -23 20 -341 178 -359 178 -7 0 -15 3 -17 8 -5 12 -204 88 -348 132 -74 23
-146 46 -160 52 -14 5 -94 12 -178 15 l-152 6 71 36 c40 20 94 57 122 82 47
42 51 50 66 125 l16 79 76 5 c48 4 85 12 100 23 29 20 62 14 104 -17z m3335
-327 c14 -5 75 -20 135 -35 61 -14 135 -34 165 -44 30 -9 91 -25 135 -34 44
-10 103 -23 130 -31 28 -7 71 -17 98 -21 26 -3 50 -10 53 -15 3 -5 15 -9 27
-9 25 0 152 -42 194 -64 37 -18 83 -74 84 -100 1 -12 2 -90 3 -174 1 -125 -2
-156 -14 -167 -8 -7 -91 -52 -184 -99 -93 -47 -176 -91 -183 -98 -12 -10 -14
-106 -11 -626 l3 -614 -44 -38 c-24 -21 -68 -50 -97 -65 -29 -15 -101 -59
-161 -98 -59 -39 -142 -88 -183 -108 -88 -45 -109 -44 -260 4 -136 43 -214 82
-229 113 -8 19 -12 127 -11 388 0 198 -4 444 -10 546 -6 102 -14 276 -19 386
-5 111 -13 210 -19 220 -5 10 -64 38 -138 65 -70 27 -136 57 -145 66 -15 15
-18 43 -21 178 -3 146 -1 163 17 195 20 35 76 69 170 103 50 19 202 82 275
114 30 14 71 31 90 40 19 8 49 21 65 29 17 7 37 11 45 8 8 -4 26 -10 40 -15z
m1769 -41 c33 -12 25 -16 -66 -39 -43 -11 -87 -22 -98 -25 -19 -6 -19 -5 -2 8
10 7 19 22 21 33 6 40 71 50 145 23z m-5437 -58 c181 -36 319 -88 626 -236
130 -64 154 -83 167 -135 3 -14 30 -50 58 -80 65 -67 116 -169 158 -314 32
-109 33 -113 33 -325 1 -212 0 -217 -32 -343 -60 -231 -168 -416 -341 -586
-110 -107 -111 -108 -376 -239 -77 -38 -134 -52 -213 -52 -85 0 -148 18 -241
70 -36 20 -112 62 -168 93 -56 31 -104 57 -108 57 -9 0 -169 92 -175 101 -18
27 -19 1313 -1 1514 14 161 34 216 118 319 98 121 319 190 495 156z m2266 -47
c150 -63 187 -88 300 -199 107 -106 115 -118 163 -221 27 -59 54 -117 60 -128
5 -11 13 -52 19 -91 5 -38 16 -99 24 -135 17 -74 35 -228 44 -378 9 -173 3
-535 -12 -644 l-15 -102 -58 -53 c-32 -29 -71 -60 -88 -69 -16 -9 -89 -57
-163 -107 -203 -139 -196 -136 -283 -136 -84 0 -119 10 -233 66 -117 58 -153
102 -162 197 -8 75 -13 97 -24 97 -6 0 -52 -21 -102 -46 -84 -41 -99 -45 -148
-42 -81 6 -194 46 -237 83 -34 30 -37 37 -39 91 -2 32 -8 131 -15 219 -15 210
-20 636 -9 750 15 141 39 316 49 357 32 133 169 298 301 364 123 61 325 129
425 145 66 10 158 2 203 -18z m3517 3 c182 -80 208 -98 316 -208 73 -75 115
-127 137 -170 62 -124 92 -200 92 -231 0 -18 5 -50 10 -72 5 -22 19 -92 30
-155 53 -305 53 -961 -1 -1063 -21 -38 -92 -97 -197 -161 -51 -32 -114 -74
-140 -94 -26 -20 -76 -54 -111 -75 l-64 -39 -91 4 c-84 3 -99 7 -188 51 -144
69 -188 125 -188 235 0 48 -8 72 -24 72 -6 0 -54 -21 -105 -46 -83 -41 -99
-46 -145 -42 -87 8 -194 46 -234 83 l-37 33 -12 199 c-24 384 -28 736 -9 895
31 257 43 314 87 397 57 106 170 216 270 263 115 54 351 134 419 142 55 6 152
-3 185 -18z"/>
<path d="M5028 8916 c-18 -24 -55 -71 -81 -105 -27 -34 -47 -69 -45 -79 2 -9
18 -24 35 -32 18 -8 33 -18 33 -22 0 -11 -38 -10 -44 1 -12 19 -48 19 -67 0
-25 -25 -24 -33 9 -50 l27 -13 -50 -6 c-40 -4 -51 -9 -53 -25 -2 -11 -13 -27
-25 -37 -17 -13 -19 -19 -9 -31 11 -12 5 -19 -33 -41 -49 -29 -55 -41 -25 -51
11 -3 20 -11 20 -16 0 -5 5 -9 12 -9 6 0 39 -13 72 -29 34 -16 69 -31 79 -34
9 -4 17 -11 17 -17 0 -17 -42 -11 -56 8 -9 13 -16 14 -24 5 -9 -8 -7 -13 9
-18 12 -3 21 -11 21 -16 0 -5 8 -12 18 -16 9 -3 24 -11 32 -18 8 -6 40 -22 70
-34 30 -13 69 -34 87 -47 18 -14 38 -21 47 -18 20 8 21 24 1 24 -22 0 -18 21
5 27 11 3 20 9 20 13 0 5 10 12 21 15 12 4 33 21 48 37 14 17 39 43 55 59 16
15 30 37 31 49 1 12 12 27 26 33 13 7 31 27 39 44 8 18 20 33 27 33 7 0 17 10
24 22 6 11 42 63 80 114 38 51 69 97 69 102 0 5 -10 16 -22 24 -26 16 -451
198 -463 198 -3 0 -19 -20 -37 -44z m92 -206 c0 -5 -2 -10 -4 -10 -3 0 -8 5
-11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-80 -60 c0 -5 -9 -10 -19 -10 -11
0 -23 5 -26 10 -4 6 5 10 19 10 14 0 26 -4 26 -10z m70 -30 c11 -7 7 -10 -17
-10 -17 0 -35 5 -38 10 -8 13 35 13 55 0z m200 0 c0 -5 -7 -10 -15 -10 -8 0
-15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m-365 -30 c3 -5 -3 -10 -14
-10 -11 0 -23 5 -26 10 -3 6 3 10 14 10 11 0 23 -4 26 -10z m244 -7 c17 -10
31 -21 31 -25 0 -12 -13 -9 -34 7 -11 8 -28 15 -37 15 -10 0 -21 5 -24 10 -10
16 31 11 64 -7z m196 7 c4 -6 -5 -10 -19 -10 -14 0 -26 5 -26 10 0 6 9 10 19
10 11 0 23 -4 26 -10z m-380 -30 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6
-2 10 4 10 5 0 13 -4 16 -10z m-158 -26 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6
11 1 17 -2 13 -5z m210 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13
-5z m-150 -30 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m218 -4
c-3 -5 -12 -7 -20 -3 -21 7 -19 13 6 13 11 0 18 -4 14 -10z m57 -32 c-9 -9
-15 -9 -24 0 -9 9 -7 12 12 12 19 0 21 -3 12 -12z m-402 -28 c0 -5 -9 -10 -19
-10 -11 0 -23 5 -26 10 -4 6 5 10 19 10 14 0 26 -4 26 -10z m447 4 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-392 -34 c8 -13 -5 -13 -25 0 -13
8 -13 10 2 10 9 0 20 -4 23 -10z m130 0 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16
10 0 6 4 10 9 10 6 0 13 -4 16 -10z m56 -28 c13 -10 33 -22 44 -27 19 -8 19
-8 2 -12 -10 -3 -26 2 -36 11 -10 9 -25 16 -34 16 -9 0 -17 7 -17 15 0 20 12
19 41 -3z m96 -58 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-173 -20 c9 -3 16 -10 16 -16 0 -14 -43 -5 -48 10 -4 13 7 15 32 6z m118 -16
c-9 -9 -15 -9 -24 0 -9 9 -7 12 12 12 19 0 21 -3 12 -12z m-47 -17 c7 -12 -12
-24 -25 -16 -11 7 -4 25 10 25 5 0 11 -4 15 -9z m105 -11 c0 -5 -7 -10 -15
-10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m-50 -30 c0 -5 -7
-10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z"/>
<path d="M3977 8633 c-14 -14 -7 -122 8 -135 9 -7 21 -18 28 -24 7 -6 23 -15
35 -18 12 -4 22 -12 22 -17 0 -12 -37 -12 -45 1 -10 16 -42 12 -49 -6 -11 -28
-6 -69 10 -92 15 -21 15 -25 0 -41 -20 -22 -21 -90 -2 -116 11 -16 11 -21 0
-33 -8 -7 -14 -29 -14 -47 0 -27 7 -40 30 -57 33 -24 39 -41 13 -36 -30 4 -43
-11 -43 -51 0 -42 10 -53 83 -91 26 -13 47 -28 47 -32 0 -12 -4 -10 -57 17
-41 20 -50 22 -62 10 -18 -18 -7 -61 19 -68 25 -7 26 -27 1 -27 -10 0 -24 -7
-31 -15 -10 -12 -8 -20 12 -44 12 -17 30 -30 39 -30 9 -1 19 -8 23 -16 3 -8
14 -15 24 -15 11 0 25 -7 32 -15 10 -12 9 -15 -5 -15 -10 0 -26 6 -34 13 -22
18 -68 37 -74 30 -13 -12 10 -35 91 -89 48 -32 89 -61 92 -64 8 -8 43 -29 68
-40 12 -5 22 -14 22 -20 0 -14 -10 -13 -29 3 -36 31 -223 137 -241 137 -50 0
4 -74 68 -94 12 -4 22 -12 22 -17 0 -5 4 -9 9 -9 10 0 31 -14 154 -96 32 -21
66 -43 75 -49 9 -5 22 -16 28 -24 6 -8 18 -11 28 -8 20 8 21 50 0 68 -14 11
-14 13 0 21 9 5 16 18 16 29 0 19 -39 63 -63 72 -7 2 -9 7 -6 11 4 4 18 1 33
-5 40 -18 53 0 30 41 -16 25 -16 32 -5 39 24 15 16 63 -15 85 l-28 21 29 5
c35 6 44 35 24 82 -9 22 -9 32 0 41 18 18 14 98 -6 120 -17 18 -17 19 0 28 20
12 24 88 5 118 -9 15 -8 22 6 36 13 14 16 30 13 81 -3 36 -7 67 -11 71 -3 3
-1 12 4 19 14 16 24 106 16 138 -4 16 -20 33 -44 44 -20 11 -110 56 -200 100
-158 80 -182 89 -195 75z m73 -343 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3
6 1 10 9 10 9 0 16 -4 16 -10z m5 -150 c-3 -5 -12 -7 -20 -3 -21 7 -19 13 6
13 11 0 18 -4 14 -10z m292 -26 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m-277 -114 c8 -5 12 -12 9 -15 -8 -8 -39 5 -39 16 0 11 11 11 30 -1z
m227 -6 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-177 -24 c8
-5 12 -12 9 -15 -8 -8 -39 5 -39 16 0 11 11 11 30 -1z m230 -6 c0 -2 -7 -4
-15 -4 -8 0 -15 4 -15 10 0 5 7 7 15 4 8 -4 15 -8 15 -10z m-182 -32 c2 -7 -2
-10 -12 -6 -9 3 -16 11 -16 16 0 13 23 5 28 -10z m-25 -119 c-7 -2 -19 -2 -25
0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m44 -29 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6
6 11 1 17 -2 13 -5z m-124 -31 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2
13 -5z m174 1 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-117
-34 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z
m210 -30 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11
-10z m-117 -24 c4 -10 1 -13 -9 -9 -7 3 -14 9 -14 14 0 14 17 10 23 -5z m47
-26 c8 -5 11 -12 8 -16 -10 -9 -38 3 -38 16 0 12 10 13 30 0z m-100 -36 c0 -2
-12 -4 -26 -4 -14 0 -23 4 -20 9 6 8 46 4 46 -5z m147 0 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z m-110 -30 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6
6 11 1 17 -2 13 -5z m50 -30 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z m48 -24 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13
-4 16 -10z m0 -90 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0
13 -4 16 -10z"/>
<path d="M5574 8547 c-12 -17 -57 -75 -101 -129 -44 -53 -107 -131 -139 -175
-32 -43 -61 -80 -64 -83 -3 -3 -17 -21 -30 -40 -45 -64 -131 -140 -159 -140
-9 0 -46 22 -81 50 -36 27 -112 82 -170 121 -58 40 -139 99 -180 132 -41 34
-81 65 -87 70 -9 6 -15 2 -22 -14 -4 -13 -12 -199 -15 -414 -4 -214 -11 -491
-16 -615 -11 -282 -3 -580 16 -587 20 -7 181 45 187 61 3 8 6 254 6 547 l0
534 27 23 c42 37 52 32 250 -115 40 -29 82 -53 94 -53 16 0 51 28 109 88 161
162 206 190 232 140 11 -21 11 -75 -7 -709 -6 -232 -6 -231 77 -199 30 12 60
27 66 33 9 9 15 106 23 342 5 182 13 391 17 465 12 200 10 693 -2 697 -5 1
-20 -12 -31 -30z"/>
<path d="M3970 7485 c0 -9 11 -27 25 -41 30 -30 31 -34 5 -34 -11 0 -20 -3
-20 -6 0 -9 83 -84 94 -84 4 0 25 -13 46 -30 21 -16 40 -30 43 -30 3 0 26 -13
50 -30 25 -16 50 -30 56 -30 6 0 11 -4 11 -8 0 -11 78 -44 93 -39 7 2 3 8 -11
16 -28 15 -28 29 0 33 34 5 33 26 -5 61 -44 40 -64 57 -72 57 -4 0 -33 20 -65
45 -32 25 -61 45 -65 45 -5 0 -64 39 -75 50 -13 13 -74 40 -91 40 -10 0 -19
-7 -19 -15z m90 -91 c0 -2 -7 -4 -15 -4 -8 0 -15 4 -15 10 0 5 7 7 15 4 8 -4
15 -8 15 -10z m35 -24 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3
0 8 -4 11 -10z m65 -40 c0 -5 -7 -7 -15 -4 -8 4 -15 8 -15 10 0 2 7 4 15 4 8
0 15 -4 15 -10z m35 -20 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10
6 0 13 -4 16 -10z m50 -30 c3 -5 -3 -10 -14 -10 -12 0 -21 5 -21 10 0 6 6 10
14 10 8 0 18 -4 21 -10z m42 -36 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m41 -32 c2 -7 -2 -10 -12 -6 -9 3 -16 11 -16 16 0 13 23 5 28 -10z"/>
<path d="M3970 7330 c0 -12 78 -70 94 -70 6 0 22 -10 36 -23 45 -42 120 -71
120 -47 0 6 -4 10 -9 10 -6 0 -30 14 -54 30 -25 17 -49 30 -55 30 -5 0 -12 7
-16 15 -3 8 -12 15 -21 15 -8 0 -15 4 -15 10 0 10 -52 40 -70 40 -5 0 -10 -4
-10 -10z"/>
<path d="M3980 7250 c0 -5 4 -10 8 -10 4 0 23 -16 42 -35 19 -19 43 -35 53
-35 24 0 22 -20 -2 -20 -11 0 -23 5 -26 10 -8 13 -55 13 -55 1 0 -5 9 -13 20
-16 11 -3 20 -10 20 -15 0 -4 21 -18 48 -30 26 -12 57 -26 69 -32 11 -6 27 -8
35 -5 9 4 6 8 -9 12 -13 4 -23 11 -23 16 0 5 -11 15 -25 21 -14 6 -25 15 -25
20 0 14 40 9 46 -7 4 -8 15 -15 26 -15 11 0 26 -7 35 -15 8 -8 19 -13 24 -9
13 7 1 24 -17 24 -8 0 -14 4 -14 9 0 11 -84 67 -164 110 -66 35 -66 35 -66 21z"/>
<path d="M4240 7155 c0 -8 6 -15 14 -15 8 0 16 -7 20 -15 3 -8 12 -15 20 -15
7 0 16 -7 20 -15 6 -17 56 -21 56 -5 0 6 -11 16 -25 22 -14 6 -25 15 -25 20 0
4 -8 8 -19 8 -10 0 -24 7 -31 15 -7 8 -16 15 -21 15 -5 0 -9 -7 -9 -15z"/>
<path d="M4002 7101 c4 -12 96 -81 107 -81 5 0 11 -3 13 -7 4 -10 58 -48 81
-56 9 -3 17 -11 17 -17 0 -5 8 -10 19 -10 10 0 24 -7 31 -15 7 -8 21 -15 31
-15 11 0 19 -4 19 -9 0 -12 39 -21 45 -11 3 5 -20 26 -51 47 -31 21 -61 43
-68 48 -6 6 -29 19 -50 30 -22 11 -44 25 -50 30 -22 21 -66 45 -81 45 -9 0
-23 7 -31 15 -17 16 -38 20 -32 6z"/>
<path d="M4205 7040 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10 -11
0 -17 -4 -14 -10z"/>
<path d="M4334 7025 c25 -23 46 -32 46 -20 0 12 -41 45 -56 45 -13 0 -10 -6
10 -25z"/>
<path d="M4040 6998 c33 -29 87 -68 96 -68 3 0 23 -13 44 -30 21 -16 43 -30
49 -30 6 0 11 -3 11 -8 0 -4 24 -18 53 -32 28 -13 57 -31 63 -39 8 -11 13 -11
18 -2 8 13 -20 51 -36 51 -5 0 -26 13 -46 29 -20 16 -56 39 -79 51 -24 12 -43
26 -43 30 0 5 -10 12 -22 16 -13 4 -25 9 -28 13 -10 12 -72 41 -88 41 -13 0
-11 -5 8 -22z"/>
<path d="M4268 7003 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4300 6980 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M8940 8917 c-47 -22 -51 -27 -37 -41 20 -21 344 -206 360 -206 8 0
37 9 65 21 34 14 49 26 45 35 -6 16 -56 45 -302 176 -56 30 -90 34 -131 15z"/>
<path d="M8770 8770 c0 -5 12 -10 26 -10 14 0 23 4 19 10 -3 6 -15 10 -26 10
-10 0 -19 -4 -19 -10z"/>
<path d="M8775 8720 c-9 -15 4 -30 26 -30 10 0 19 -4 19 -10 0 -14 -36 -13
-60 2 -15 10 -22 10 -31 1 -18 -18 -3 -43 31 -49 16 -4 35 -13 41 -20 6 -8 19
-14 29 -14 10 0 23 -7 30 -15 7 -8 16 -13 20 -10 4 2 16 -2 26 -10 24 -18 67
-20 62 -2 -3 6 -13 14 -24 16 -10 2 -23 9 -27 17 -4 8 -14 14 -21 14 -8 0 -16
7 -20 15 -3 8 -12 15 -20 15 -8 0 -18 5 -21 10 -4 6 5 10 19 10 17 0 26 -6 26
-16 0 -8 5 -12 10 -9 6 4 22 0 35 -9 13 -9 31 -16 40 -16 9 0 18 -7 21 -15 4
-8 12 -15 19 -15 7 0 18 -7 25 -15 14 -17 50 -20 50 -5 0 6 -8 14 -17 17 -10
4 -20 9 -23 12 -8 9 -63 46 -80 54 -8 4 -28 16 -45 27 -16 11 -37 23 -45 26
-8 3 -29 12 -47 20 -37 17 -40 17 -48 4z"/>
<path d="M8125 8656 c-16 -8 -38 -17 -47 -20 -10 -3 -18 -9 -18 -14 0 -5 46
-32 103 -60 56 -29 135 -70 176 -93 l73 -40 59 17 c33 10 59 22 59 26 0 5 -12
16 -27 24 -16 8 -64 36 -108 61 -128 73 -208 113 -225 113 -8 0 -28 -7 -45
-14z"/>
<path d="M8716 8610 c7 -28 21 -40 47 -40 9 0 17 -4 17 -10 0 -5 -6 -10 -14
-10 -22 0 -30 -19 -10 -27 10 -4 20 0 26 10 6 12 17 15 38 10 26 -5 30 -2 30
16 0 16 -6 21 -25 21 -16 0 -25 6 -25 15 0 11 -11 15 -39 15 -22 0 -42 6 -45
13 -3 6 -3 1 0 -13z"/>
<path d="M9355 8584 c-21 -8 -50 -24 -63 -37 -22 -20 -92 -155 -92 -177 0 -5
-18 -46 -39 -92 -21 -46 -49 -118 -60 -161 -34 -122 -48 -147 -86 -163 -19 -8
-43 -14 -55 -14 -24 0 -77 69 -120 157 -17 34 -44 78 -61 99 -16 21 -44 64
-61 97 -41 76 -60 88 -118 77 -56 -11 -96 -26 -104 -39 -4 -6 1 -25 10 -43 16
-31 139 -219 173 -264 9 -12 22 -30 29 -40 7 -11 35 -50 63 -89 122 -172 125
-182 73 -292 -41 -90 -48 -105 -99 -228 -21 -49 -51 -117 -68 -150 -69 -137
-181 -421 -171 -436 9 -16 32 -10 122 32 55 25 94 49 100 62 19 38 172 396
199 462 14 37 28 54 54 67 19 9 39 17 45 18 25 1 66 -46 157 -183 53 -79 103
-150 111 -156 11 -10 26 -8 68 10 93 39 96 52 33 149 -20 30 -42 69 -49 86 -7
16 -43 78 -80 136 -36 58 -66 111 -66 117 0 6 -18 38 -40 70 -28 40 -40 69
-40 93 0 35 23 111 47 157 7 15 13 32 13 39 0 7 4 20 9 30 5 9 26 58 46 107
20 50 65 153 100 230 81 181 115 262 115 275 0 14 -48 11 -95 -6z m-349 -780
c-3 -9 -6 -23 -7 -32 0 -14 -2 -13 -9 3 -13 31 -11 45 6 45 11 0 14 -5 10 -16z
m57 -204 c33 -44 50 -101 30 -100 -19 1 -48 33 -49 53 -1 12 -9 39 -18 60 -23
51 -7 46 37 -13z"/>
<path d="M8872 8543 c-15 -6 -6 -23 14 -23 8 0 23 -8 33 -17 47 -43 102 -73
133 -73 25 0 23 18 -4 31 -34 15 -68 35 -81 48 -6 6 -21 11 -34 11 -13 0 -23
4 -23 9 0 11 -21 19 -38 14z"/>
<path d="M8985 8530 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10 -11
0 -17 -4 -14 -10z"/>
<path d="M8800 8505 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
<path d="M9046 8512 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 -7 7 -13 7 -17 1z"/>
<path d="M8745 8470 c3 -5 15 -10 26 -10 10 0 19 5 19 10 0 6 -12 10 -26 10
-14 0 -23 -4 -19 -10z"/>
<path d="M8850 8470 c0 -5 -11 -10 -25 -10 -16 0 -25 -6 -25 -15 0 -8 9 -15
19 -15 11 0 26 -7 35 -15 18 -18 49 -20 44 -2 -3 6 -15 13 -27 15 -27 4 -22
26 7 30 30 5 28 22 -3 22 -14 0 -25 -4 -25 -10z"/>
<path d="M8920 8439 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M8970 8410 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M8920 8384 c0 -8 7 -14 16 -14 8 0 22 -7 30 -15 19 -18 57 -20 52 -2
-3 6 -17 13 -31 15 -15 2 -27 7 -27 11 0 5 -9 11 -20 14 -13 3 -20 0 -20 -9z"/>
<path d="M8217 8304 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z"/>
<path d="M8270 8212 c0 -10 55 -52 68 -52 5 0 15 -7 22 -15 7 -8 22 -15 33
-15 11 0 28 -7 38 -16 10 -9 23 -13 29 -9 19 12 -15 40 -94 79 -73 36 -96 43
-96 28z"/>
<path d="M8357 8063 c-13 -12 -7 -23 12 -23 11 0 23 -4 26 -10 3 -5 15 -10 26
-10 10 0 19 5 19 10 0 6 -11 10 -24 10 -13 0 -26 7 -30 15 -6 16 -18 19 -29 8z"/>
<path d="M8488 7988 c9 -9 15 -9 24 0 9 9 7 12 -12 12 -19 0 -21 -3 -12 -12z"/>
<path d="M8535 7960 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M8350 7841 c-16 -31 -12 -59 13 -76 22 -17 21 -17 -14 -11 -40 7 -49
-3 -49 -50 0 -19 5 -24 24 -24 13 0 29 -7 36 -15 7 -8 20 -15 30 -15 10 0 23
-6 29 -14 7 -8 23 -17 36 -20 14 -4 25 -11 25 -16 0 -6 7 -10 16 -10 8 0 39
-13 69 -30 56 -31 110 -39 117 -18 3 7 2 23 0 35 -3 18 -11 23 -35 23 -21 0
-28 3 -21 10 7 7 19 8 37 1 15 -6 34 -8 42 -5 17 7 29 43 23 70 -3 18 -318
183 -350 183 -10 1 -22 -8 -28 -18z m260 -211 c0 -5 -2 -10 -4 -10 -3 0 -8 5
-11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z"/>
<path d="M8283 7663 c-20 -7 -15 -29 9 -45 21 -14 21 -15 -20 -22 -41 -6 -56
-26 -31 -42 6 -3 -2 -14 -20 -25 -39 -24 -41 -56 -5 -65 32 -8 13 -24 -30 -24
-26 0 -28 -2 -23 -29 5 -25 3 -30 -16 -33 -15 -2 -23 -11 -25 -28 -2 -13 -13
-36 -24 -49 -19 -24 -19 -26 -1 -39 10 -8 45 -27 78 -43 33 -17 67 -34 75 -39
8 -5 22 -11 30 -14 8 -3 22 -10 30 -15 8 -5 22 -12 30 -15 8 -4 35 -17 59 -31
24 -14 52 -25 63 -25 37 0 16 30 -44 66 -54 31 -109 74 -95 74 16 0 117 -55
126 -68 3 -5 16 -12 28 -16 16 -5 25 -2 33 14 17 32 -2 53 -81 90 -39 18 -80
41 -90 51 -24 24 -5 24 37 1 114 -62 145 -74 159 -62 8 7 15 20 15 30 0 9 9
25 20 35 12 11 20 31 20 52 0 18 5 33 10 33 6 0 10 11 10 25 0 17 -8 29 -25
37 -14 6 -25 15 -25 20 0 12 14 10 35 -6 14 -10 25 -11 37 -5 25 13 22 36 -4
50 -13 7 -29 18 -36 26 -7 7 -20 13 -28 13 -7 0 -36 14 -62 30 -27 17 -54 30
-61 30 -6 0 -11 5 -11 10 0 6 -9 10 -19 10 -11 0 -26 6 -33 13 -7 7 -22 16
-33 19 -11 3 -27 8 -35 11 -8 3 -21 3 -27 0z m182 -133 c8 0 15 -6 15 -14 0
-17 -34 -10 -51 10 -6 8 -18 14 -26 14 -8 0 -11 5 -8 11 5 7 15 5 31 -5 13 -9
30 -16 39 -16z m-170 -10 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4
10 5 0 13 -4 16 -10z m240 -30 c3 -5 -3 -10 -14 -10 -12 0 -21 5 -21 10 0 6 6
10 14 10 8 0 18 -4 21 -10z m-168 -6 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z m-80 -60 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-62 -54 c3 -5 -3 -10 -14 -10 -11 0 -23 5 -26 10 -3 6 3 10 14 10 11 0 23 -4
26 -10z m-50 -60 c3 -5 -3 -10 -14 -10 -12 0 -21 5 -21 10 0 6 6 10 14 10 8 0
18 -4 21 -10z m50 -30 c18 -8 17 -9 -7 -9 -16 -1 -28 4 -28 9 0 12 8 12 35 0z"/>
<path d="M8075 7220 c-3 -6 2 -13 12 -17 10 -3 26 -13 35 -21 10 -8 24 -12 33
-9 25 10 17 27 -14 27 -16 0 -31 6 -35 15 -6 17 -22 20 -31 5z"/>
<path d="M8050 7162 c0 -5 11 -14 25 -20 14 -6 25 -16 25 -21 0 -5 10 -12 23
-16 18 -6 19 -8 5 -12 -9 -2 -26 1 -37 6 -24 13 -58 14 -65 2 -6 -9 70 -51 92
-51 18 0 14 -21 -5 -36 -17 -13 -17 -13 0 -14 9 0 17 5 17 10 0 13 47 13 55 1
3 -5 -3 -12 -14 -16 -11 -3 -22 -12 -25 -20 -3 -8 -13 -15 -21 -15 -9 0 -13
-4 -10 -10 3 -5 15 -10 26 -10 10 0 19 5 19 10 0 6 6 10 14 10 8 0 16 7 20 15
3 8 14 15 24 15 30 0 34 12 10 37 -15 16 -17 23 -8 23 20 0 142 -62 156 -79
12 -15 45 -12 51 5 4 12 -124 95 -165 108 -12 4 -22 12 -22 17 0 5 -7 9 -15 9
-8 0 -32 9 -52 20 -21 11 -43 20 -50 20 -7 0 -13 5 -13 10 0 6 -13 10 -30 10
-16 0 -30 -4 -30 -8z m147 -98 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z"/>
<path d="M8180 7161 c0 -6 5 -13 10 -16 14 -9 52 4 45 16 -8 12 -55 12 -55 0z"/>
<path d="M8242 7128 c3 -7 15 -14 27 -16 12 -2 25 -10 28 -18 3 -8 16 -14 29
-14 13 0 24 -4 24 -8 0 -9 70 -42 88 -42 7 0 12 4 12 9 0 13 -47 41 -70 41
-11 0 -23 7 -26 15 -4 8 -17 15 -30 15 -14 0 -27 7 -30 15 -7 18 -58 21 -52 3z"/>
<path d="M8265 6980 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M8325 6950 c-3 -5 3 -10 14 -10 12 0 21 5 21 10 0 6 -6 10 -14 10 -8
0 -18 -4 -21 -10z"/>
<path d="M8185 6935 l-30 -13 57 -26 c31 -14 60 -26 63 -26 3 0 22 -12 41 -26
19 -15 37 -24 40 -21 11 10 -28 57 -47 57 -11 0 -22 7 -25 15 -4 8 -17 15 -30
15 -17 0 -24 6 -24 20 0 23 -4 23 -45 5z"/>
<path d="M10667 8813 c-13 -13 -7 -21 26 -34 l32 -13 -34 -4 c-19 -2 -59 4
-88 13 -52 17 -55 16 -160 -10 -59 -15 -115 -32 -125 -39 -16 -12 -10 -16 57
-41 80 -29 105 -58 38 -45 -46 9 -194 49 -204 55 -12 8 -84 -22 -84 -35 0 -7
27 -24 60 -38 33 -13 73 -30 90 -38 16 -7 80 -30 141 -51 l112 -37 64 22 c73
25 393 107 464 119 34 6 50 14 52 26 4 19 14 15 -207 96 -150 56 -217 72 -234
54z m-180 -189 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M9980 8530 c0 -5 9 -10 20 -10 11 0 23 -6 26 -14 3 -8 20 -18 37 -22
17 -3 37 -13 45 -20 7 -8 24 -14 37 -14 12 0 25 -7 29 -15 3 -9 19 -15 41 -15
19 0 35 -4 35 -10 0 -5 16 -10 35 -10 19 0 35 5 35 10 0 6 -5 10 -11 10 -10 0
-53 18 -158 67 -55 25 -136 53 -158 53 -7 0 -13 -4 -13 -10z"/>
<path d="M11198 8535 c-2 -3 -31 -9 -64 -14 -32 -5 -95 -19 -139 -31 -44 -12
-93 -25 -110 -27 -16 -3 -68 -17 -115 -30 -47 -14 -102 -29 -122 -33 -43 -8
-88 -35 -89 -53 -7 -96 -7 -167 1 -172 5 -3 37 3 71 15 82 27 111 25 149 -7
l31 -26 -7 -301 c-4 -166 -7 -403 -8 -527 -1 -208 -3 -229 -20 -248 -11 -12
-25 -21 -33 -21 -20 0 -165 -60 -181 -75 -15 -13 -26 -180 -15 -224 5 -20 10
-22 37 -18 29 5 364 138 481 191 27 13 77 34 110 47 86 33 92 41 100 137 6 79
6 82 -14 82 -12 0 -29 -4 -39 -10 -37 -21 -124 -50 -152 -50 -16 0 -41 11 -57
24 -27 23 -28 28 -28 118 0 51 6 278 13 503 15 448 14 446 74 464 18 5 67 23
108 40 l75 29 3 111 3 111 -29 0 c-16 0 -31 -2 -34 -5z m-54 -124 c12 -18 -6
-39 -47 -55 -95 -36 -103 -6 -11 42 45 24 51 25 58 13z"/>
<path d="M9985 8470 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M10050 8440 c0 -13 20 -13 40 0 12 8 9 10 -12 10 -16 0 -28 -4 -28
-10z"/>
<path d="M10135 8410 c-3 -5 1 -10 9 -10 8 0 18 5 21 10 3 6 -1 10 -9 10 -8 0
-18 -4 -21 -10z"/>
<path d="M10332 8388 c3 -7 20 -14 39 -16 l34 -2 -25 15 c-31 18 -53 19 -48 3z"/>
<path d="M10090 8380 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M10112 8331 c-10 -6 -7 -10 13 -15 32 -8 46 1 25 14 -18 12 -22 12
-38 1z"/>
<path d="M10176 8301 c-3 -5 1 -12 10 -15 27 -10 44 -7 44 9 0 16 -45 21 -54
6z"/>
<path d="M10246 8271 c-3 -5 1 -12 10 -15 27 -10 44 -7 44 9 0 16 -45 21 -54
6z"/>
<path d="M10370 8020 c0 -5 6 -10 14 -10 8 0 18 5 21 10 3 6 -3 10 -14 10 -12
0 -21 -4 -21 -10z"/>
<path d="M10286 7998 c-3 -7 -4 -20 -5 -28 0 -8 -1 -22 -2 -30 -1 -8 -1 -34
-1 -58 2 -35 6 -44 27 -54 14 -6 25 -15 25 -20 0 -4 -11 -8 -25 -8 -22 0 -25
-4 -25 -39 0 -36 3 -41 43 -60 24 -12 50 -21 59 -21 9 0 21 -6 28 -14 6 -8 26
-16 43 -18 18 -2 32 -7 32 -13 0 -17 -41 -17 -58 -1 -9 9 -27 16 -41 16 -13 0
-31 7 -40 15 -16 17 -66 21 -66 5 0 -5 9 -15 20 -22 25 -16 25 -24 -1 -31 -20
-5 -20 -7 -4 -31 10 -14 27 -26 38 -26 26 0 107 -39 107 -51 0 -5 -14 -9 -31
-9 -20 0 -29 -4 -27 -12 7 -20 38 -21 58 -3 12 11 26 14 39 10 24 -7 28 -25 6
-25 -8 0 -15 -5 -15 -11 0 -11 16 -9 50 5 9 4 24 0 35 -8 20 -15 20 -15 -2
-16 -27 0 -40 -18 -19 -26 9 -3 22 1 30 9 12 11 24 13 51 7 46 -10 60 13 26
41 l-23 19 26 10 c24 9 26 14 26 73 0 38 -6 71 -14 82 -11 16 -11 21 0 33 19
19 17 41 -3 49 -10 3 -45 18 -78 33 -33 16 -70 31 -82 35 -34 9 -29 29 5 20
15 -3 29 -11 30 -16 2 -5 16 -9 32 -9 15 0 33 -6 39 -14 16 -19 48 -26 60 -12
11 13 -19 56 -39 56 -7 0 -24 6 -38 13 -15 8 -42 21 -60 30 -19 9 -42 17 -52
17 -9 0 -23 6 -30 14 -6 8 -25 16 -42 18 -39 4 -51 28 -15 28 14 0 32 -7 41
-15 8 -8 20 -15 26 -15 14 0 62 -20 142 -59 73 -36 101 -16 62 44 -13 20 -25
25 -55 25 -29 0 -39 4 -39 16 0 11 -6 14 -20 9 -32 -10 -62 4 -55 26 7 20 -9
26 -21 8 -7 -12 -93 -3 -108 12 -15 13 -66 11 -70 -3z m49 -28 c3 -5 -1 -10
-10 -10 -9 0 -13 5 -10 10 3 6 8 10 10 10 2 0 7 -4 10 -10z m59 -100 c5 -5 18
-10 27 -10 9 0 22 -7 29 -15 10 -12 10 -15 -3 -15 -22 0 -103 38 -117 54 -10
13 -7 14 21 6 18 -5 37 -14 43 -20z m15 -86 c2 -2 2 -7 -2 -10 -9 -10 -57 5
-57 17 0 10 48 4 59 -7z m81 -34 c0 -12 -17 -12 -45 0 -17 7 -15 9 13 9 17 1
32 -3 32 -9z m68 -31 c2 -5 13 -9 25 -9 12 0 29 -5 37 -10 13 -8 12 -10 -4
-10 -27 0 -116 33 -116 43 0 12 54 -1 58 -14z m-179 -108 c9 -6 10 -10 2 -15
-16 -10 -73 3 -65 15 7 11 45 12 63 0z m174 -8 c-7 -2 -21 -2 -30 0 -10 3 -4
5 12 5 17 0 24 -2 18 -5z m-110 -30 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0
24 -2 18 -5z m70 -30 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z
m74 -29 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M10597 8003 c-14 -13 -6 -23 18 -23 16 0 25 6 25 15 0 15 -31 21 -43
8z"/>
<path d="M10322 7518 c7 -20 48 -23 48 -4 0 11 -9 16 -26 16 -16 0 -24 -5 -22
-12z"/>
<path d="M10342 7459 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
0 -15 -5 -13 -11z"/>
<path d="M10390 7425 c0 -10 10 -15 31 -15 35 0 36 -23 1 -28 -12 -2 -22 -8
-22 -13 0 -12 37 -12 45 1 9 15 68 13 63 -2 -3 -7 -15 -14 -27 -16 -28 -4 -22
-26 8 -30 12 -2 25 -10 28 -18 3 -8 16 -14 29 -14 15 0 24 -6 24 -15 0 -9 9
-15 24 -15 14 0 28 -4 31 -10 3 -5 13 -10 21 -10 23 0 -12 48 -38 52 -31 4
-35 28 -6 28 13 0 30 -6 37 -12 11 -11 13 -10 7 5 -3 10 -6 22 -6 27 0 4 -11
11 -25 14 -26 7 -35 26 -11 26 7 0 19 7 26 15 11 13 8 15 -19 15 -17 0 -31 -4
-31 -9 0 -17 -28 -19 -55 -5 -15 8 -37 14 -50 14 -12 0 -28 7 -35 15 -7 8 -21
15 -31 15 -11 0 -19 -7 -19 -15z m173 -90 c-3 -13 -53 -20 -53 -7 0 15 13 23
35 20 11 -2 19 -7 18 -13z"/>
<path d="M10295 7370 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M10346 7341 c-3 -5 1 -11 9 -15 15 -6 29 7 18 18 -9 9 -21 8 -27 -3z"/>
<path d="M10197 7319 c-18 -10 -18 -12 -1 -30 16 -18 16 -19 -7 -18 -13 0 -33
4 -44 8 -13 6 -33 2 -59 -11 -38 -20 -39 -21 -20 -35 24 -19 146 -76 282 -134
l103 -43 75 37 c56 28 74 42 74 57 0 11 -8 24 -17 27 -10 4 -46 24 -81 45 -34
21 -67 38 -73 38 -6 0 -23 7 -37 15 -95 51 -158 66 -195 44z"/>
<path d="M10392 7308 c3 -7 17 -14 31 -16 15 -2 27 -10 27 -18 0 -8 10 -14 24
-14 14 0 28 -5 31 -11 4 -5 17 -14 28 -20 33 -14 61 -33 70 -46 10 -16 47 -17
47 -3 0 15 -50 60 -66 60 -8 0 -14 5 -14 10 0 6 -11 10 -25 10 -14 0 -28 7
-31 15 -4 8 -17 15 -30 15 -14 0 -27 7 -30 15 -8 19 -68 21 -62 3z"/>
<path d="M10000 7040 c0 -5 11 -12 24 -16 14 -3 27 -12 30 -20 3 -8 17 -14 31
-14 16 0 25 -6 25 -15 0 -10 10 -15 30 -15 17 0 30 -4 30 -9 0 -5 10 -13 23
-16 20 -6 77 -33 128 -60 22 -11 25 -25 7 -25 -14 0 -140 61 -148 71 -3 4 -20
10 -37 14 -18 3 -33 10 -33 15 0 5 -13 11 -30 15 -16 4 -32 11 -35 16 -4 5
-21 9 -38 9 l-32 -1 35 -29 c19 -16 41 -30 48 -30 7 0 15 -7 18 -15 4 -9 19
-15 35 -15 35 0 38 -13 7 -28 -23 -10 -22 -11 10 -23 17 -8 32 -17 32 -21 0
-4 14 -8 30 -8 17 0 30 5 30 10 0 6 -13 10 -30 10 -20 0 -30 5 -30 15 0 8 7
15 15 15 14 0 76 -24 105 -41 8 -5 25 -13 38 -19 27 -12 29 -30 2 -30 -11 0
-20 -4 -20 -10 0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 14 10 30 10 38 0 40 22
3 40 l-28 13 33 9 c27 7 32 13 32 38 0 28 -2 30 -38 30 -44 0 -56 8 -41 26 8
10 3 12 -25 7 -25 -4 -40 -1 -51 11 -9 9 -23 16 -31 16 -14 0 -22 3 -82 33
-19 9 -44 17 -57 17 -12 0 -28 7 -35 15 -14 17 -50 20 -50 5z"/>
<path d="M10235 6800 c3 -5 15 -10 26 -10 11 0 17 5 14 10 -3 6 -15 10 -26 10
-11 0 -17 -4 -14 -10z"/>
<path d="M10365 6740 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10
-11 0 -17 -4 -14 -10z"/>
<path d="M6680 8731 c-19 -4 -54 -16 -77 -26 -62 -27 -58 -53 12 -85 30 -14
55 -28 55 -32 0 -4 12 -14 28 -22 35 -20 13 -21 -25 -1 -15 8 -58 28 -96 45
-63 28 -70 29 -96 16 -15 -8 -43 -31 -60 -52 l-32 -36 22 -24 c12 -13 32 -27
43 -30 12 -4 23 -9 26 -13 3 -3 14 -11 25 -18 101 -60 161 -99 163 -105 2 -5
15 -8 28 -8 18 0 30 9 42 31 56 102 158 204 255 254 44 23 61 40 53 54 -22 34
-279 71 -366 52z"/>
<path d="M7075 8537 c-43 -9 -60 -20 -125 -86 -132 -134 -204 -327 -246 -651
-15 -117 -16 -198 -12 -583 4 -325 8 -451 17 -460 16 -16 134 45 166 87 22 29
24 43 32 236 l8 205 55 32 c30 18 63 33 75 33 11 0 48 13 82 30 156 73 199 49
201 -110 1 -47 5 -117 8 -157 7 -83 19 -94 79 -68 32 13 36 18 31 39 -3 14 -1
69 4 123 42 401 27 897 -34 1108 -35 126 -71 179 -140 211 -55 26 -115 29
-201 11z m240 -297 c13 -29 15 -44 8 -51 -8 -8 -14 -5 -22 10 -7 12 -18 21
-25 21 -8 0 -19 7 -26 15 -10 13 -9 15 9 15 15 0 21 6 21 21 0 29 13 17 35
-31z m-65 -118 c14 -11 39 -41 55 -67 30 -49 30 -49 28 -188 -2 -152 -13 -187
-56 -187 -13 0 -61 -13 -108 -28 -46 -16 -110 -34 -141 -42 -52 -12 -60 -11
-77 4 -29 26 -43 93 -44 207 -1 85 3 114 20 158 47 116 96 160 188 171 58 7
99 -2 135 -28z m40 -602 c-8 -5 -24 -10 -35 -10 -17 0 -17 2 -5 10 8 5 24 10
35 10 17 0 17 -2 5 -10z"/>
<path d="M6353 8482 c-12 -8 -43 -73 -43 -90 0 -5 19 -22 43 -38 23 -16 44
-31 47 -34 3 -3 32 -23 65 -45 33 -22 62 -43 65 -46 15 -18 100 -59 112 -54 8
3 22 30 33 60 15 47 16 59 5 79 -7 13 -20 27 -29 30 -9 3 -29 15 -46 25 -16
11 -50 30 -75 43 -25 13 -47 25 -50 29 -9 10 -91 49 -103 49 -7 0 -17 -4 -24
-8z"/>
<path d="M6265 8310 c-8 -16 -15 -35 -15 -43 0 -13 56 -57 100 -80 34 -17 94
-55 160 -100 39 -27 78 -47 88 -45 10 2 21 18 27 42 10 44 20 34 -121 135 -92
67 -186 121 -208 121 -9 0 -22 -13 -31 -30z"/>
<path d="M6237 8194 c-14 -15 -7 -51 16 -71 27 -26 113 -80 193 -123 32 -18
61 -35 64 -39 12 -17 71 -34 81 -24 22 22 4 58 -46 91 -27 18 -60 42 -73 53
-12 10 -26 19 -32 19 -5 0 -13 6 -17 13 -9 14 -37 32 -73 48 -13 5 -27 14 -30
19 -7 11 -74 23 -83 14z"/>
<path d="M6217 8083 c-19 -18 -1 -44 58 -86 36 -26 67 -47 69 -47 3 0 34 -20
69 -44 35 -24 73 -47 85 -50 12 -4 22 -12 22 -17 0 -6 12 -9 28 -7 19 2 28 9
30 25 4 25 -25 63 -49 63 -9 0 -22 7 -29 15 -7 8 -17 15 -22 15 -9 0 -69 40
-78 52 -5 6 -165 88 -173 88 -2 0 -7 -3 -10 -7z"/>
<path d="M6197 7974 c-14 -14 15 -43 83 -85 39 -24 83 -52 98 -61 15 -10 33
-21 41 -25 8 -3 37 -21 65 -40 63 -42 89 -43 84 -6 -3 23 -26 41 -163 124
-147 90 -191 110 -208 93z"/>
<path d="M6184 7875 c-3 -8 -3 -18 0 -23 6 -10 153 -112 161 -112 3 0 24 -12
48 -27 60 -39 80 -51 97 -59 8 -4 23 -14 32 -22 23 -19 48 -8 48 21 0 17 -14
31 -62 59 -35 20 -75 46 -89 59 -27 26 -199 119 -218 119 -6 0 -14 -7 -17 -15z"/>
<path d="M6187 7793 c-24 -23 19 -65 142 -138 9 -5 32 -21 51 -35 60 -44 155
-100 168 -100 9 0 12 10 10 31 -2 25 -11 36 -43 53 -22 11 -73 44 -113 71 -84
57 -80 55 -150 95 -59 34 -56 32 -65 23z"/>
<path d="M6180 7678 c0 -18 25 -40 112 -98 62 -41 129 -83 148 -94 19 -10 42
-24 50 -31 26 -22 57 -27 63 -11 4 9 -6 25 -26 41 -42 33 -42 34 -112 80 -33
22 -61 43 -63 48 -2 4 -8 7 -14 7 -5 0 -24 11 -41 24 -34 25 -94 56 -109 56
-4 0 -8 -10 -8 -22z"/>
<path d="M6160 7542 c0 -16 50 -72 65 -72 7 0 15 -3 17 -7 8 -18 197 -151 245
-172 11 -5 24 -15 28 -20 8 -13 45 -15 45 -2 0 29 -314 262 -372 276 -17 4
-28 3 -28 -3z"/>
<path d="M6392 7458 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"/>
<path d="M6170 7428 c0 -15 45 -47 206 -151 132 -86 162 -102 170 -94 16 15
-14 46 -87 92 -44 28 -87 56 -94 63 -8 6 -17 12 -21 12 -4 0 -15 6 -23 14 -22
19 -127 76 -140 76 -6 0 -11 -6 -11 -12z"/>
<path d="M6430 7431 c0 -12 89 -71 107 -71 31 0 6 30 -54 63 -34 19 -53 22
-53 8z"/>
<path d="M6173 7334 c-7 -20 8 -34 82 -74 33 -18 65 -38 71 -44 6 -6 22 -18
34 -26 12 -8 31 -23 42 -32 12 -10 25 -18 29 -18 4 0 28 -13 52 -30 47 -32 77
-38 77 -17 -1 21 -22 40 -115 102 -97 65 -94 63 -187 118 -74 42 -76 43 -85
21z"/>
<path d="M6186 7232 c-5 -9 54 -62 69 -62 4 0 27 -13 52 -30 24 -16 46 -30 48
-30 2 0 43 -27 92 -60 90 -62 113 -71 113 -45 0 13 -79 75 -95 75 -4 0 -24 14
-45 30 -21 17 -40 30 -43 30 -3 0 -26 14 -50 30 -25 17 -48 30 -53 30 -4 0
-15 6 -23 14 -22 19 -58 29 -65 18z"/>
<path d="M6300 7072 c0 -12 236 -181 253 -182 4 0 7 8 7 18 -1 20 -99 96 -183
141 -59 32 -77 37 -77 23z"/>
<path d="M6220 7042 c0 -4 15 -15 33 -25 18 -10 46 -31 63 -46 30 -28 144 -95
150 -88 5 5 -94 77 -105 77 -5 0 -11 6 -14 13 -2 7 -25 23 -51 35 -25 13 -48
27 -51 33 -7 11 -25 12 -25 1z"/>
<path d="M6480 6860 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M8475 6385 c-161 -76 -248 -115 -254 -115 -4 0 -16 -9 -25 -19 -17
-18 -15 -20 51 -40 37 -12 91 -28 118 -36 28 -7 73 -20 100 -29 107 -31 348
-86 381 -86 19 0 34 -4 34 -9 0 -10 83 -41 109 -41 8 0 25 9 37 21 13 12 58
36 101 54 190 80 198 84 198 102 0 29 -66 41 -121 20 -50 -18 -87 -14 -58 8
20 14 11 35 -15 35 -9 0 -89 19 -177 41 -87 23 -177 45 -199 49 -22 4 -59 12
-82 19 -35 9 -49 9 -79 -4 -20 -8 -43 -15 -51 -15 -8 0 -21 -7 -29 -15 -17
-17 -44 -20 -44 -5 0 6 6 10 14 10 23 0 70 45 62 58 -9 15 -34 14 -71 -3z"/>
<path d="M8005 6126 c-13 -9 -15 -28 -13 -91 l3 -80 35 -17 c19 -9 53 -23 75
-31 118 -44 138 -55 171 -89 l36 -36 9 -204 c23 -538 30 -784 29 -1027 -1
-223 1 -267 15 -287 8 -13 21 -24 28 -24 6 0 40 -12 75 -27 34 -14 66 -23 70
-19 4 4 7 346 7 759 0 802 -1 793 48 814 27 12 89 1 245 -43 68 -20 130 -33
139 -30 12 5 14 23 11 96 -3 101 -13 117 -75 130 -21 5 -72 18 -113 29 -429
116 -635 166 -752 184 -16 2 -35 -1 -43 -7z"/>
<path d="M9340 6029 c-35 -16 -67 -33 -70 -38 -3 -4 -14 -10 -25 -14 -47 -14
-111 -39 -122 -49 -21 -17 -15 -38 11 -38 22 0 84 27 96 41 10 13 85 49 102
49 23 0 23 -19 1 -27 -33 -11 -148 -63 -163 -73 -8 -6 -23 -13 -32 -16 -11 -4
-18 -16 -18 -31 0 -19 4 -24 16 -19 9 3 27 9 40 12 13 3 24 10 24 15 0 5 6 9
13 9 6 0 23 6 37 13 14 7 48 21 75 31 70 26 135 65 135 81 0 8 -8 18 -17 24
-15 8 -16 13 -5 30 6 12 12 23 12 26 0 13 -51 1 -110 -26z m70 -33 c0 -2 -11
-6 -25 -8 -14 -3 -25 -1 -25 3 0 5 11 9 25 9 14 0 25 -2 25 -4z"/>
<path d="M8697 5624 c-29 -16 -36 -34 -13 -34 19 0 61 36 50 42 -5 4 -21 0
-37 -8z"/>
<path d="M8987 5554 c-9 -10 2 -24 19 -24 8 0 14 7 14 15 0 15 -21 21 -33 9z"/>
<path d="M8928 5517 c-18 -18 -197 -107 -215 -107 -12 0 -35 -40 -28 -48 6 -6
140 61 195 97 30 20 63 39 73 43 10 4 15 12 12 18 -8 14 -21 13 -37 -3z"/>
<path d="M8905 5430 c-55 -27 -105 -50 -111 -50 -7 0 -14 -7 -18 -15 -3 -8
-13 -15 -22 -15 -19 0 -74 -37 -74 -51 0 -17 32 -9 94 24 33 17 63 34 66 37
15 15 71 50 79 50 21 0 78 33 89 51 7 10 9 19 5 19 -5 0 -53 -23 -108 -50z"/>
<path d="M8695 5450 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M8950 5396 c-72 -48 -141 -88 -212 -123 -50 -24 -57 -38 -56 -103 0
-19 -1 -42 -2 -50 -2 -8 -2 -36 -2 -62 2 -42 5 -48 23 -48 12 0 47 15 78 34
31 18 81 46 111 61 30 15 62 31 70 36 8 5 27 14 43 20 40 16 65 69 32 69 -14
0 -58 -17 -121 -48 -17 -8 -37 -13 -45 -10 -9 3 -2 11 21 22 121 59 155 83
155 107 0 20 -4 24 -28 21 -18 -2 -26 1 -22 7 3 6 12 11 18 11 7 0 21 16 31
35 15 28 17 37 6 50 -18 22 -28 19 -100 -29z m20 -96 c-20 -13 -53 -13 -45 0
3 6 18 10 33 10 21 0 24 -2 12 -10z m-125 -150 c-3 -5 -13 -10 -21 -10 -8 0
-12 5 -9 10 3 6 13 10 21 10 8 0 12 -4 9 -10z m-55 -30 c-20 -13 -33 -13 -25
0 3 6 14 10 23 10 15 0 15 -2 2 -10z"/>
<path d="M8923 5100 c-53 -28 -104 -56 -113 -63 -8 -7 -36 -23 -62 -37 -90
-46 -91 -112 -1 -69 26 13 55 28 63 35 8 6 25 15 37 18 18 6 22 4 17 -8 -3 -9
-14 -16 -24 -16 -10 0 -25 -6 -32 -12 -20 -20 -74 -48 -90 -48 -8 0 -22 -15
-30 -34 -14 -28 -14 -37 -4 -52 10 -13 10 -24 2 -45 -8 -21 -7 -32 2 -41 9 -9
26 -5 77 23 36 18 65 37 65 42 0 4 5 7 12 7 6 0 19 6 27 14 9 7 48 30 86 51
100 53 125 78 89 92 -20 7 -82 -20 -174 -78 -41 -26 -88 -51 -105 -54 l-30 -7
20 20 c11 11 76 50 145 87 121 64 166 97 154 116 -8 14 -42 11 -75 -7 -17 -8
-36 -13 -42 -11 -7 3 0 10 16 16 15 6 33 15 40 21 6 5 25 20 41 33 20 16 27
29 23 40 -10 26 -28 21 -134 -33z m-18 -100 c-3 -5 -10 -10 -16 -10 -5 0 -9 5
-9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z"/>
<path d="M8900 4790 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M8798 4737 c-32 -17 -58 -35 -58 -39 0 -12 34 -10 47 3 14 14 52 36
76 45 20 7 23 24 5 23 -7 0 -39 -15 -70 -32z"/>
<path d="M8960 4760 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M8900 4730 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M9015 4730 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M8973 4713 c-18 -6 -16 -23 1 -23 17 0 30 16 19 23 -5 2 -14 2 -20 0z"/>
<path d="M8860 4700 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M8710 4669 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M8820 4670 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M8900 4671 c0 -5 -13 -12 -30 -16 -16 -4 -30 -11 -30 -16 0 -5 -11
-11 -25 -15 -14 -3 -25 -10 -25 -15 0 -5 -8 -9 -17 -9 -21 0 -69 -47 -59 -57
4 -4 19 0 34 9 15 9 43 24 62 35 19 10 40 24 48 31 7 6 20 12 28 12 8 0 14 7
14 15 0 8 9 15 20 15 11 0 20 5 20 10 0 6 -9 10 -20 10 -11 0 -20 -4 -20 -9z"/>
<path d="M8960 4635 c0 -9 -9 -15 -25 -15 -14 0 -25 -4 -25 -10 0 -5 -8 -10
-18 -10 -10 0 -25 -9 -35 -20 -9 -11 -24 -20 -32 -20 -8 0 -15 -4 -15 -9 0 -5
-9 -13 -20 -16 -22 -7 -27 -25 -7 -25 11 0 196 116 215 134 3 3 -4 6 -16 6
-13 0 -22 -6 -22 -15z"/>
<path d="M9000 4550 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M8985 4501 c-3 -6 -4 -15 -1 -20 8 -13 48 -1 43 13 -5 15 -34 20 -42
7z"/>
<path d="M8725 4490 c-3 -5 3 -10 14 -10 12 0 21 5 21 10 0 6 -6 10 -14 10 -8
0 -18 -4 -21 -10z"/>
<path d="M8950 4460 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M8755 4299 c-16 -11 -36 -24 -44 -28 -8 -5 -11 -14 -6 -21 4 -7 10
-8 14 -3 3 5 27 22 54 37 26 15 47 30 47 32 0 11 -38 0 -65 -17z"/>
<path d="M4753 6281 c-24 -4 -46 -10 -49 -14 -13 -12 6 -39 36 -50 31 -12 40
-27 17 -27 -7 0 -21 6 -32 13 -11 7 -40 19 -64 27 -41 13 -46 13 -97 -14 -30
-16 -54 -34 -54 -40 0 -16 94 -75 185 -117 41 -19 75 -38 75 -42 0 -9 -42 2
-50 13 -3 4 -17 10 -32 14 -16 3 -28 11 -28 16 0 6 -7 10 -16 10 -9 0 -28 6
-42 14 -15 7 -45 21 -67 31 l-39 17 -24 -27 c-18 -21 -22 -37 -20 -69 3 -35 8
-43 35 -56 43 -20 41 -30 -6 -30 -45 0 -55 -20 -43 -80 6 -28 17 -39 54 -59
26 -14 45 -28 42 -33 -3 -4 -18 -1 -34 7 -39 20 -70 19 -71 -2 -4 -59 18 -113
46 -113 7 0 17 -5 21 -11 4 -7 -6 -10 -32 -7 -37 3 -39 2 -42 -29 -3 -28 2
-35 40 -60 54 -34 96 -58 127 -73 13 -6 22 -13 19 -15 -7 -7 -48 7 -48 16 0 5
-8 9 -17 9 -9 0 -36 9 -60 21 -45 22 -63 19 -63 -10 0 -25 24 -44 135 -100 36
-18 45 -26 32 -29 -10 -2 -26 3 -36 12 -10 9 -23 16 -30 16 -7 0 -25 7 -41 16
-50 30 -81 -6 -43 -49 17 -18 17 -19 0 -32 -38 -27 -19 -61 58 -102 22 -12 60
-33 85 -46 25 -14 65 -36 90 -49 25 -12 47 -26 50 -29 6 -8 118 -59 129 -59 5
0 14 6 20 14 16 19 -1 52 -29 61 -29 9 -25 22 10 29 24 4 30 11 30 30 0 14
-10 32 -22 41 l-23 15 22 6 c49 13 28 61 -42 95 -35 17 -66 36 -78 48 -20 20
22 13 51 -9 15 -11 45 -23 66 -27 l37 -6 -3 38 c-3 32 -9 40 -43 59 -22 11
-42 24 -45 28 -3 3 -17 9 -32 13 -15 4 -32 13 -39 21 -6 8 -17 14 -23 14 -6 1
-40 19 -76 41 -57 36 -61 40 -32 35 19 -3 75 -30 125 -59 160 -94 180 -93 176
10 -1 32 3 71 9 86 18 44 15 84 -8 100 -20 14 -20 15 -1 20 31 8 56 56 58 113
1 49 4 56 55 106 56 57 183 133 237 144 64 13 35 39 -97 85 -189 65 -309 86
-399 70z m86 -107 c2 -2 2 -7 -2 -10 -9 -10 -57 5 -57 17 0 10 48 4 59 -7z
m-6 -181 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m57 -33 c0
-5 -7 -7 -15 -4 -8 4 -15 8 -15 10 0 2 7 4 15 4 8 0 15 -4 15 -10z m-204 -85
c75 -38 106 -56 114 -66 3 -4 15 -9 28 -13 12 -4 22 -12 22 -17 0 -12 -1 -12
-51 11 -42 20 -57 28 -96 50 -10 6 -27 14 -38 20 -29 13 -72 50 -59 50 5 0 41
-16 80 -35z m30 -186 c9 -15 -23 -10 -35 5 -10 12 -8 13 9 9 11 -3 23 -9 26
-14z m54 -29 c12 -8 11 -10 -7 -10 -12 0 -25 5 -28 10 -8 13 15 13 35 0z
m-214 -32 c4 -5 -4 -8 -17 -6 -12 1 -24 8 -26 15 -5 14 34 6 43 -9z m264 8 c0
-3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-165
-276 c3 -5 -3 -10 -14 -10 -11 0 -23 5 -26 10 -3 6 3 10 14 10 11 0 23 -4 26
-10z m-114 -45 c24 -14 51 -28 59 -31 18 -7 90 -43 148 -74 23 -12 45 -26 48
-31 9 -15 -23 -10 -36 6 -7 8 -18 15 -26 15 -7 0 -35 12 -61 28 -26 15 -83 44
-125 65 -43 21 -78 40 -78 43 0 11 31 2 71 -21z m164 15 c3 -5 -1 -10 -9 -10
-8 0 -18 5 -21 10 -3 6 1 10 9 10 8 0 18 -4 21 -10z m60 -30 c8 -13 -15 -13
-35 0 -12 8 -11 10 7 10 12 0 25 -4 28 -10z m52 -26 c-3 -3 -12 -4 -19 -1 -8
3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M5260 5993 c-25 -10 -69 -31 -97 -47 -49 -27 -53 -33 -72 -95 -40
-129 -51 -186 -51 -256 0 -38 -7 -124 -15 -190 -13 -99 -15 -207 -10 -625 3
-299 9 -508 15 -514 6 -6 24 -5 49 3 42 12 246 115 271 136 8 7 25 16 38 20
13 4 67 50 121 103 73 72 109 118 142 177 49 89 89 182 89 205 0 9 4 20 9 25
31 35 54 251 49 460 -5 172 -6 184 -38 275 -18 52 -41 113 -52 134 -26 51
-110 135 -173 171 -43 25 -61 30 -140 32 -68 3 -101 -1 -135 -14z m234 -450
c55 -37 92 -78 118 -132 20 -39 23 -60 21 -141 -2 -115 -13 -168 -53 -251 -34
-73 -48 -91 -106 -137 -59 -45 -122 -72 -172 -72 -37 0 -49 5 -74 32 l-30 33
6 200 c3 110 9 246 14 303 7 95 10 105 38 132 29 30 110 58 168 59 17 1 48
-11 70 -26z"/>
<path d="M4430 5223 c0 -24 36 -47 214 -137 109 -56 154 -74 172 -70 28 7 33
34 6 34 -10 0 -26 6 -37 13 -11 8 -65 38 -120 67 -132 70 -137 73 -155 86 -8
7 -30 15 -47 19 -28 5 -33 4 -33 -12z"/>
<path d="M4426 5152 c-12 -20 30 -53 118 -96 52 -25 97 -46 100 -46 3 0 38
-20 78 -44 117 -70 167 -71 154 -2 -7 36 -8 37 -42 30 -28 -5 -53 1 -122 32
-48 21 -100 47 -117 58 -41 29 -135 76 -151 76 -7 0 -15 -4 -18 -8z"/>
<path d="M4435 5069 c-19 -29 26 -63 140 -109 48 -19 153 -78 161 -91 3 -5 14
-9 25 -9 10 0 19 -4 19 -10 0 -12 65 -24 87 -16 14 6 15 9 2 21 -14 13 -193
108 -314 166 -33 16 -72 36 -87 43 -17 9 -30 11 -33 5z"/>
<path d="M4460 4980 c-12 -8 -12 -12 6 -27 44 -39 93 -69 232 -139 146 -75
166 -82 177 -65 6 11 -96 84 -132 95 -13 4 -23 12 -23 17 0 5 -8 9 -19 9 -10
0 -24 7 -31 15 -7 8 -17 15 -23 15 -13 0 -160 72 -167 82 -3 4 -12 3 -20 -2z"/>
<path d="M4436 4896 c6 -15 135 -96 153 -96 6 0 11 -3 11 -8 0 -8 91 -52 108
-52 6 0 12 -3 14 -7 2 -5 26 -18 53 -31 28 -13 58 -27 67 -33 24 -13 50 -11
46 2 -4 12 -19 20 -138 80 -102 51 -125 64 -130 70 -11 13 -164 89 -178 89 -6
0 -9 -6 -6 -14z"/>
<path d="M4500 4790 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M4540 4769 c0 -5 14 -15 30 -20 17 -6 30 -15 30 -20 0 -5 14 -9 30
-9 20 0 30 -5 30 -15 0 -10 10 -15 30 -15 39 0 39 19 1 26 -17 4 -32 12 -35
20 -3 8 -12 14 -20 14 -7 0 -27 7 -42 15 -34 17 -54 19 -54 4z"/>
<path d="M4477 4743 c-10 -9 -9 -23 2 -23 6 0 30 -13 54 -29 23 -17 83 -51
133 -76 49 -26 115 -61 147 -78 41 -22 61 -27 69 -19 16 16 -29 56 -104 92
-34 17 -81 42 -103 55 -22 13 -47 24 -56 25 -9 0 -19 7 -23 15 -3 8 -16 15
-29 15 -13 0 -27 4 -32 9 -15 13 -50 22 -58 14z"/>
<path d="M4730 4670 c20 -13 43 -13 35 0 -3 6 -16 10 -28 10 -18 0 -19 -2 -7
-10z"/>
<path d="M4500 4645 c0 -3 10 -10 23 -15 12 -6 36 -19 52 -30 17 -11 53 -31
80 -45 55 -28 76 -40 117 -67 15 -10 36 -18 48 -18 43 0 16 27 -68 68 -48 23
-100 51 -117 62 -16 11 -37 22 -45 26 -8 3 -23 10 -32 15 -19 10 -58 12 -58 4z"/>
<path d="M4460 4601 c0 -17 133 -101 295 -184 97 -50 124 -58 134 -38 11 22 3
41 -18 41 -24 0 -46 11 -271 128 -107 55 -140 68 -140 53z"/>
<path d="M4446 4521 c-7 -11 21 -28 184 -111 69 -35 149 -76 178 -91 59 -32
72 -35 72 -16 0 12 -174 117 -193 117 -9 0 -141 62 -147 69 -8 9 -70 41 -80
41 -5 0 -11 -4 -14 -9z"/>
<path d="M6915 6265 c-5 -2 -49 -15 -97 -30 -49 -15 -88 -30 -88 -34 0 -3 17
-15 38 -26 121 -63 226 -160 284 -262 16 -29 36 -53 43 -53 7 0 51 23 97 51
46 27 92 56 103 62 11 7 22 15 25 18 3 3 24 16 48 29 23 13 42 31 42 40 0 9
-19 35 -43 58 -34 34 -50 42 -79 42 -20 0 -40 -4 -43 -10 -9 -15 -97 -51 -115
-47 -9 2 8 16 42 36 75 43 88 59 63 77 -48 35 -125 54 -218 53 -51 0 -96 -2
-102 -4z"/>
<path d="M6505 6049 c-22 -11 -48 -31 -58 -42 -58 -71 -108 -255 -124 -457
-12 -159 -10 -507 6 -771 l14 -237 40 -12 c63 -19 67 -9 74 179 6 153 7 163
30 187 21 23 30 26 67 22 24 -3 67 -17 97 -30 30 -14 68 -28 84 -32 50 -12
132 -57 143 -80 6 -11 13 -108 16 -214 6 -228 11 -241 109 -292 98 -50 93 -57
101 148 8 194 9 730 3 802 -20 217 -54 374 -114 525 -47 119 -72 156 -158 241
-79 79 -93 84 -214 84 -58 0 -86 -5 -116 -21z m250 -402 c47 -26 95 -93 120
-168 19 -58 23 -255 6 -287 -5 -10 -7 -29 -4 -43 4 -21 0 -27 -19 -32 -38 -11
-85 -8 -135 8 -26 9 -79 25 -118 36 -38 12 -86 29 -105 38 l-35 16 -8 90 c-18
200 7 285 101 342 51 32 141 31 197 0z"/>
<path d="M7420 6010 c-25 -7 -190 -89 -200 -100 -12 -12 -70 -50 -77 -50 -5 0
-14 -10 -22 -22 -11 -18 -11 -29 5 -77 19 -56 29 -71 50 -71 6 0 57 32 115 71
57 38 125 85 151 102 26 18 50 40 53 49 6 16 -26 92 -42 101 -5 2 -19 1 -33
-3z"/>
<path d="M7455 5842 c-16 -9 -56 -36 -90 -59 -33 -23 -88 -61 -123 -85 -67
-45 -79 -67 -63 -111 15 -40 39 -34 140 31 50 31 122 77 160 101 77 47 89 66
69 108 -16 34 -50 39 -93 15z"/>
<path d="M7513 5709 c-18 -5 -33 -14 -33 -19 0 -6 -9 -10 -19 -10 -11 0 -32
-11 -47 -25 -15 -14 -37 -31 -49 -38 -22 -12 -87 -58 -137 -97 -30 -23 -36
-48 -16 -68 19 -19 34 -15 96 27 31 21 77 49 102 62 25 13 47 26 50 29 3 3 31
22 62 43 60 40 72 58 63 88 -7 21 -23 22 -72 8z"/>
<path d="M7530 5585 c-14 -8 -32 -17 -40 -21 -8 -4 -46 -27 -85 -52 -111 -71
-114 -72 -128 -72 -23 0 -59 -46 -55 -72 4 -33 41 -35 86 -5 20 13 78 50 127
82 126 82 165 114 165 136 0 23 -33 25 -70 4z"/>
<path d="M7555 5481 c-22 -10 -94 -50 -160 -90 -66 -39 -127 -75 -135 -80 -22
-12 -37 -58 -23 -72 8 -8 36 4 102 45 50 31 95 56 100 56 5 0 14 7 21 15 6 8
41 31 76 52 61 36 86 65 67 84 -4 4 -26 0 -48 -10z"/>
<path d="M7570 5390 c-8 -5 -39 -22 -68 -39 -29 -17 -57 -31 -62 -31 -5 0 -22
-12 -37 -26 -30 -27 -138 -94 -153 -94 -12 0 -22 -34 -14 -54 10 -26 34 -19
104 27 36 24 85 54 110 68 25 13 47 26 50 29 3 3 14 11 25 18 11 7 35 22 53
33 36 23 52 47 45 67 -5 14 -31 15 -53 2z"/>
<path d="M7528 5261 c-42 -21 -86 -48 -98 -59 -12 -11 -50 -37 -84 -58 -77
-46 -106 -73 -106 -95 0 -30 30 -30 76 1 25 16 47 30 50 30 9 0 240 169 253
184 12 14 8 36 -7 36 -4 0 -42 -18 -84 -39z"/>
<path d="M7605 5202 c-18 -4 -161 -91 -195 -119 -8 -7 -48 -35 -87 -63 -76
-52 -78 -56 -64 -79 8 -12 14 -11 39 5 17 10 66 41 109 67 122 75 216 140 227
159 13 22 -1 36 -29 30z"/>
<path d="M7565 5022 c-127 -89 -155 -109 -209 -151 -88 -70 -109 -92 -97 -104
7 -7 33 4 83 33 40 24 77 48 83 52 5 5 56 43 113 84 67 50 102 83 102 95 0 27
-28 23 -75 -9z"/>
<path d="M7345 4937 c-63 -37 -85 -53 -85 -64 0 -21 22 -14 69 20 59 43 71 76
16 44z"/>
<path d="M7575 4917 c-49 -27 -69 -40 -212 -134 -106 -70 -137 -103 -95 -103
9 0 46 19 82 42 187 119 290 192 290 204 0 19 -18 17 -65 -9z"/>
<path d="M7520 4793 c-52 -31 -97 -59 -100 -62 -3 -4 -38 -28 -77 -54 -78 -51
-103 -73 -103 -88 0 -32 127 29 234 114 27 21 68 48 90 59 54 27 76 47 76 70
0 28 -17 22 -120 -39z"/>
<path d="M7574 4717 c-12 -6 -45 -25 -75 -41 -30 -16 -56 -31 -59 -35 -3 -3
-43 -31 -90 -61 -83 -52 -110 -75 -110 -92 0 -20 36 -3 124 56 53 36 127 83
165 106 38 22 77 50 86 60 17 19 17 20 -1 20 -10 0 -28 -6 -40 -13z"/>
<path d="M7406 4525 c-92 -52 -166 -111 -166 -131 0 -24 21 -16 99 38 88 61
84 58 136 103 56 48 25 44 -69 -10z"/>
<path d="M7550 4520 c-11 -11 -26 -20 -34 -20 -8 0 -16 -3 -18 -8 -1 -4 -29
-24 -60 -45 -31 -21 -60 -42 -63 -47 -14 -23 19 -7 92 45 43 31 86 58 96 61 9
4 17 13 17 20 0 19 -6 18 -30 -6z"/>
<path d="M7325 4370 c-3 -5 1 -10 9 -10 8 0 18 5 21 10 3 6 -1 10 -9 10 -8 0
-18 -4 -21 -10z"/>
<path d="M10405 6255 c-27 -7 -66 -19 -85 -26 -19 -6 -41 -13 -47 -15 -25 -6
-12 -20 47 -52 115 -64 208 -157 272 -271 21 -39 38 -39 93 -1 24 17 46 30 50
30 3 0 11 5 18 11 7 6 50 34 96 62 47 29 86 57 88 64 3 7 -15 32 -38 58 -51
55 -80 58 -162 15 -63 -32 -98 -40 -78 -17 6 8 28 22 48 33 46 23 73 44 73 56
0 5 -26 21 -57 36 -50 22 -73 27 -163 29 -64 1 -125 -3 -155 -12z m232 -171
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M10029 6050 c-63 -33 -107 -114 -140 -259 -37 -161 -61 -534 -48
-736 5 -66 14 -209 20 -317 l12 -196 41 -11 c58 -14 66 -4 67 88 0 42 4 118 7
169 6 82 10 96 31 113 31 25 69 24 133 -4 29 -13 85 -34 124 -46 38 -13 86
-36 105 -51 l34 -29 5 -204 c5 -202 5 -204 32 -239 28 -37 128 -91 157 -86 14
3 17 30 24 198 10 257 2 794 -13 895 -46 302 -109 485 -204 595 -71 82 -128
128 -164 134 -77 12 -186 6 -223 -14z m257 -405 c47 -28 83 -80 115 -167 14
-40 19 -79 19 -165 0 -68 -4 -114 -11 -118 -6 -4 -7 -19 -4 -36 6 -25 3 -30
-22 -40 -15 -6 -47 -9 -69 -6 -48 5 -231 60 -284 85 -42 21 -49 47 -49 205 -1
112 5 135 53 200 51 70 171 89 252 42z"/>
<path d="M10945 6011 c-22 -8 -79 -35 -110 -53 -43 -24 -51 -28 -65 -35 -8 -3
-31 -19 -51 -35 -20 -15 -41 -28 -46 -28 -5 0 -16 -11 -23 -25 -11 -21 -10
-33 5 -80 9 -31 24 -59 32 -62 15 -6 73 21 101 48 10 8 37 28 62 43 25 14 72
46 105 70 69 49 79 79 45 134 -21 34 -23 34 -55 23z"/>
<path d="M10968 5830 c-27 -17 -51 -30 -54 -30 -4 0 -27 -17 -52 -37 -56 -46
-110 -83 -121 -83 -32 0 -55 -82 -29 -108 22 -22 45 -13 151 57 57 38 113 71
123 75 10 3 39 23 63 44 l43 37 -15 38 c-20 46 -44 48 -109 7z"/>
<path d="M11030 5702 c-55 -27 -141 -79 -150 -92 -3 -3 -35 -26 -72 -50 -70
-45 -84 -65 -73 -100 9 -28 35 -25 83 10 23 17 46 30 52 30 5 0 10 4 10 9 0 5
9 11 20 14 11 3 63 34 115 68 93 62 96 65 93 98 -2 19 -7 36 -13 37 -5 2 -35
-9 -65 -24z"/>
<path d="M11040 5575 c-25 -14 -60 -33 -77 -42 -18 -9 -33 -20 -33 -25 0 -4
-4 -8 -9 -8 -6 0 -30 -13 -54 -30 -25 -16 -51 -30 -59 -30 -22 0 -58 -43 -58
-68 0 -34 28 -38 79 -11 25 13 48 26 51 29 10 10 69 50 74 50 6 0 136 88 159
108 9 8 17 23 17 34 0 26 -36 23 -90 -7z"/>
<path d="M11090 5487 c-71 -37 -147 -81 -160 -92 -8 -7 -48 -31 -88 -55 -75
-44 -96 -67 -86 -94 9 -22 15 -21 84 22 34 20 79 47 100 59 97 57 195 131 195
148 0 24 -14 28 -45 12z"/>
<path d="M11030 5352 c-47 -26 -108 -64 -135 -84 -28 -20 -69 -47 -93 -58 -37
-19 -43 -26 -40 -48 5 -41 29 -37 109 15 41 26 90 56 109 67 19 11 44 24 55
30 65 37 115 81 115 103 0 34 -22 29 -120 -25z"/>
<path d="M11058 5262 c-133 -74 -278 -176 -284 -200 -9 -33 8 -47 37 -32 29
15 190 124 279 189 104 76 82 107 -32 43z"/>
<path d="M11127 5203 c-11 -3 -58 -32 -105 -65 -48 -33 -122 -83 -164 -111
-49 -33 -78 -58 -78 -70 0 -30 28 -30 67 0 21 15 54 37 73 47 112 62 245 160
245 180 0 21 -10 26 -38 19z"/>
<path d="M11055 4995 c-116 -79 -256 -188 -269 -207 -18 -29 3 -33 48 -9 24
12 108 69 187 125 100 71 145 109 147 124 5 36 -26 27 -113 -33z"/>
<path d="M10935 4970 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M10853 4924 c-61 -29 -83 -51 -60 -60 15 -5 60 21 100 59 28 28 18
28 -40 1z"/>
<path d="M11100 4914 c-25 -13 -72 -42 -105 -64 -33 -22 -69 -44 -80 -50 -43
-21 -140 -96 -135 -105 11 -22 52 -4 180 80 152 99 210 142 210 156 0 15 -22
10 -70 -17z"/>
<path d="M11120 4837 c-82 -44 -341 -219 -347 -235 -11 -28 13 -31 59 -8 65
33 119 67 143 89 26 25 121 87 133 87 19 0 62 42 62 61 0 22 -15 24 -50 6z"/>
<path d="M11075 4704 c-92 -49 -305 -195 -305 -210 0 -14 36 -20 42 -6 3 8
136 97 237 158 34 22 75 49 90 61 24 20 24 22 6 23 -11 0 -43 -12 -70 -26z"/>
<path d="M10965 4547 c-52 -29 -162 -104 -187 -129 -20 -20 -17 -38 7 -38 7 0
20 6 27 13 7 7 47 36 88 65 41 28 91 65 110 81 29 25 32 30 15 30 -11 0 -38
-10 -60 -22z"/>
<path d="M11070 4515 c-7 -8 -17 -15 -22 -15 -5 0 -25 -12 -44 -26 -19 -15
-49 -34 -66 -44 -32 -17 -49 -40 -29 -40 13 0 104 61 114 78 4 6 12 12 18 12
5 0 15 5 22 10 7 6 21 17 32 25 16 12 17 14 4 15 -9 0 -22 -7 -29 -15z"/>
<path d="M10855 4370 c-3 -5 1 -10 9 -10 8 0 18 5 21 10 3 6 -1 10 -9 10 -8 0
-18 -4 -21 -10z"/>
<path d="M10820 4339 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M8134 3856 c-40 -18 -85 -111 -89 -183 -1 -34 -9 -60 -24 -78 -36
-46 -29 -55 43 -55 l65 0 -1 64 c-1 40 4 71 12 80 24 30 33 18 26 -38 -5 -45
-3 -58 14 -79 27 -34 77 -43 132 -24 38 13 43 17 41 43 -2 25 10 43 29 44 4 0
10 -15 14 -34 8 -46 57 -71 119 -62 59 9 81 27 66 53 -9 18 -7 23 14 37 19 13
25 25 25 55 0 90 -118 106 -189 26 l-31 -35 -6 24 c-15 61 -98 80 -168 37 -35
-22 -36 -22 -36 -3 0 11 -7 22 -15 26 -25 9 -18 45 10 52 23 6 52 40 43 50
-13 12 -67 12 -94 0z m176 -171 c0 -8 -9 -15 -20 -15 -20 0 -26 11 -13 23 12
13 33 7 33 -8z m230 0 c0 -8 -9 -15 -20 -15 -20 0 -26 11 -13 23 12 13 33 7
33 -8z m-222 -77 c-3 -7 -17 -13 -33 -13 -16 0 -30 6 -32 13 -3 8 7 12 32 12
25 0 35 -4 33 -12z m232 -4 c-15 -17 -61 -15 -68 4 -2 8 9 12 38 12 37 0 41
-2 30 -16z"/>
<path d="M5170 3840 c-6 -12 -8 -29 -5 -39 3 -11 -4 -60 -16 -110 -18 -75 -25
-91 -41 -91 -17 0 -18 5 -12 64 6 61 5 65 -19 81 -44 29 -149 16 -163 -19 -3
-8 -1 -23 4 -33 8 -14 4 -25 -19 -51 -37 -42 -36 -82 1 -97 16 -7 104 -9 237
-7 237 4 240 5 196 71 -30 45 -29 67 5 83 15 7 28 23 30 36 3 21 0 22 -58 22
l-61 0 6 55 7 55 -40 0 c-31 0 -44 -5 -52 -20z m-165 -150 c-3 -5 -12 -10 -20
-10 -8 0 -17 5 -20 10 -4 6 5 10 20 10 15 0 24 -4 20 -10z m232 -86 c-9 -9
-28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13 4 -17z m-249 4 c-6 -18 -28 -21 -28
-4 0 9 7 16 16 16 9 0 14 -5 12 -12z"/>
<path d="M6254 3838 c-4 -13 -7 -34 -5 -46 4 -35 -28 -162 -50 -198 -31 -52
-28 -54 80 -54 92 0 102 2 130 26 l31 26 0 -26 c0 -25 2 -26 60 -26 50 0 60 3
61 18 1 9 2 35 3 57 1 22 5 61 10 88 l8 47 -45 0 c-49 0 -65 -18 -52 -60 7
-23 -12 -90 -26 -90 -5 0 -7 23 -3 54 6 46 4 56 -15 75 -14 14 -34 21 -60 21
-42 0 -53 13 -40 45 4 11 8 30 8 43 1 20 -4 22 -43 22 -37 0 -46 -4 -52 -22z
m106 -181 c0 -40 -29 -74 -48 -55 -16 16 -15 39 4 66 23 33 44 28 44 -11z"/>
<path d="M7183 3844 c-3 -9 -6 -32 -7 -53 -1 -36 -1 -36 -53 -41 -58 -6 -88
-28 -109 -77 -18 -43 -18 -83 2 -111 14 -20 23 -22 113 -22 74 0 102 4 111 15
10 12 15 12 38 -4 23 -16 40 -18 114 -14 75 5 88 8 94 25 3 10 1 25 -6 33 -8
10 -8 23 1 53 6 22 9 51 5 65 -8 34 -70 59 -117 47 -66 -15 -72 -20 -66 -56 4
-24 0 -37 -14 -49 -10 -10 -21 -26 -24 -36 -7 -21 -35 -26 -35 -6 0 6 13 62
29 122 16 61 27 113 24 118 -2 4 -24 7 -49 7 -32 0 -46 -5 -51 -16z m-35 -180
c4 -37 -19 -70 -43 -60 -14 5 -16 13 -11 38 12 61 48 76 54 22z m242 26 c0
-13 -20 -13 -40 0 -12 8 -9 10 13 10 15 0 27 -4 27 -10z m-14 -89 c-10 -16
-36 -13 -36 3 0 17 21 27 34 16 4 -5 5 -13 2 -19z"/>
<path d="M8704 3849 c-3 -6 -8 -36 -10 -67 -4 -70 -30 -171 -49 -192 -8 -9
-15 -24 -15 -33 0 -14 11 -17 59 -17 51 0 61 3 66 20 4 12 2 27 -4 33 -9 12 0
66 38 212 6 22 11 43 11 48 0 11 -88 8 -96 -4z"/>
<path d="M8967 3853 c-4 -3 -7 -28 -7 -54 0 -59 -26 -164 -51 -205 -30 -50
-26 -55 44 -52 l62 3 -2 44 c-1 24 9 89 22 145 14 55 25 107 25 114 0 13 -81
18 -93 5z"/>
<path d="M9142 3844 c-33 -23 -27 -68 10 -72 18 -2 31 4 42 20 20 28 20 33 0
52 -19 20 -24 20 -52 0z"/>
<path d="M9266 3843 c-3 -10 -5 -31 -4 -48 1 -44 -28 -158 -52 -202 -11 -21
-20 -41 -20 -45 0 -5 56 -8 124 -8 115 0 125 2 131 20 4 12 -2 30 -14 47 -28
40 -31 77 -6 81 29 6 45 22 45 43 0 17 -8 19 -64 19 l-64 0 9 38 c16 68 14 72
-35 72 -34 0 -46 -4 -50 -17z m64 -228 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10
15 0 8 5 15 10 15 6 0 10 -7 10 -15z"/>
<path d="M10250 3840 c-6 -12 -8 -29 -5 -40 8 -24 -32 -190 -51 -211 -8 -8
-14 -23 -14 -32 0 -15 13 -17 90 -17 106 0 140 13 164 64 17 37 21 103 7 125
-5 8 -31 17 -58 20 -52 6 -54 9 -38 79 l8 32 -46 0 c-36 0 -49 -4 -57 -20z
m108 -178 c5 -31 -24 -73 -43 -66 -31 12 -7 98 25 92 8 -2 16 -14 18 -26z"/>
<path d="M5460 3830 c-16 -30 -3 -60 25 -60 28 0 60 41 51 65 -8 23 -63 19
-76 -5z"/>
<path d="M6530 3830 c-16 -30 -3 -60 25 -60 28 0 60 41 51 65 -8 23 -63 19
-76 -5z"/>
<path d="M4504 3825 c-4 -8 -4 -27 -1 -41 5 -29 -27 -174 -41 -183 -14 -10
-32 -48 -25 -55 3 -3 28 -6 55 -6 43 0 50 3 61 27 9 21 9 27 -1 31 -16 5 -15
34 2 58 14 18 15 18 21 -8 4 -15 4 -36 0 -47 -13 -42 3 -61 49 -61 34 0 47 5
59 23 l16 22 0 -23 c1 -22 4 -23 69 -20 l67 3 0 50 c1 70 25 166 48 192 33 37
16 53 -57 53 -72 0 -85 -8 -113 -68 -17 -38 -33 -55 -42 -46 -3 3 -8 29 -11
57 l-5 52 -73 3 c-58 2 -73 0 -78 -13z m242 -200 c-4 -14 -13 -25 -21 -25 -20
0 -19 20 2 50 19 27 29 14 19 -25z"/>
<path d="M7604 3802 c-12 -4 -33 -21 -47 -37 -20 -25 -25 -41 -24 -80 1 -28
-2 -66 -6 -85 -9 -48 4 -65 52 -65 28 0 45 6 54 19 10 14 16 16 21 7 14 -23
51 -29 136 -22 l85 6 2 92 c2 85 0 94 -20 108 -26 18 -103 19 -137 2 -20 -11
-25 -20 -23 -47 1 -19 -2 -37 -6 -40 -4 -3 -17 -20 -30 -37 -23 -34 -45 -42
-53 -20 -9 21 18 82 40 92 26 12 34 41 16 56 -8 6 -14 22 -14 35 0 24 -8 27
-46 16z m186 -112 c0 -6 -11 -9 -25 -7 -37 5 -42 17 -7 17 18 0 32 -5 32 -10z
m-20 -86 c0 -8 -7 -14 -15 -14 -15 0 -21 21 -9 33 10 9 24 -2 24 -19z"/>
<path d="M9920 3758 c-50 -14 -66 -32 -53 -60 9 -19 6 -28 -19 -56 -36 -42
-35 -81 2 -98 16 -7 57 -10 111 -7 l86 5 0 95 c1 86 -1 95 -20 107 -33 20 -68
25 -107 14z m34 -68 c-7 -7 -19 -7 -35 -1 -23 9 -22 10 11 10 23 1 31 -2 24
-9z m-14 -86 c0 -8 -7 -14 -15 -14 -15 0 -21 21 -9 33 10 9 24 -2 24 -19z"/>
<path d="M5707 3754 c-4 -4 -36 -6 -71 -5 -73 2 -75 -1 -74 -77 1 -29 -6 -53
-21 -72 -41 -56 -34 -59 105 -59 117 0 126 1 131 19 7 25 9 25 40 0 14 -11 39
-20 54 -20 16 0 29 -5 29 -10 0 -18 -31 -24 -62 -12 -38 14 -58 6 -58 -23 0
-54 118 -58 181 -5 29 23 40 44 59 107 12 43 31 87 41 99 38 41 26 52 -61 56
-44 2 -94 0 -110 -5 -40 -10 -90 -73 -90 -114 0 -18 -5 -33 -10 -33 -6 0 -9
25 -8 63 2 43 -2 69 -11 80 -13 17 -52 23 -64 11z m240 -90 c3 -35 -20 -69
-42 -61 -20 8 -19 46 1 75 20 30 38 23 41 -14z m-255 -7 c-2 -16 -7 -40 -13
-54 -8 -21 -12 -23 -24 -13 -29 24 -10 105 23 98 12 -3 16 -12 14 -31z"/>
<path d="M9577 3746 c-49 -18 -67 -34 -84 -73 -42 -101 25 -166 132 -131 36
12 40 17 39 46 -1 18 2 32 7 32 5 0 16 11 25 25 24 36 11 80 -31 99 -39 19
-39 19 -88 2z m51 -64 c2 -7 -6 -12 -17 -12 -21 0 -27 11 -14 24 9 9 26 2 31
-12z m12 -72 c0 -12 -45 -24 -59 -15 -22 13 -10 25 24 25 19 0 35 -4 35 -10z"/>
<path d="M10568 3753 c-44 -3 -68 -8 -68 -16 -4 -98 -7 -118 -21 -134 -9 -10
-20 -28 -24 -41 -7 -23 -5 -23 57 -20 l63 3 0 51 c0 51 16 84 40 84 17 0 32
20 40 53 5 20 4 26 -6 25 -8 -1 -44 -3 -81 -5z"/>
<path d="M10758 3753 c-15 -2 -46 -21 -68 -42 -36 -34 -40 -42 -40 -88 0 -36
5 -55 19 -67 26 -24 79 -30 126 -14 36 12 40 17 38 45 -2 23 2 32 17 36 23 6
30 0 30 -28 0 -48 82 -77 150 -52 30 11 35 17 33 42 -2 19 4 33 17 42 27 19
27 83 1 107 -37 34 -107 25 -158 -19 -38 -32 -42 -34 -48 -16 -13 41 -60 63
-117 54z m40 -71 c2 -7 -7 -12 -23 -12 -24 0 -26 2 -15 15 14 17 31 16 38 -3z
m222 3 c0 -9 -9 -15 -21 -15 -18 0 -19 2 -9 15 7 8 16 15 21 15 5 0 9 -7 9
-15z m-210 -75 c0 -12 -45 -24 -59 -15 -22 13 -10 25 24 25 19 0 35 -4 35 -10z
m230 0 c0 -19 -51 -23 -68 -6 -15 15 -13 16 26 16 23 0 42 -4 42 -10z"/>
<path d="M11456 3750 c-41 -13 -85 -58 -97 -101 -9 -29 -15 -35 -31 -31 -32 8
-32 30 1 58 19 16 31 35 31 50 0 24 -1 24 -95 24 -83 0 -96 -2 -105 -19 -15
-29 -12 -52 8 -67 16 -12 15 -15 -14 -46 -18 -18 -35 -43 -39 -55 -6 -23 -6
-23 98 -23 l105 0 11 32 12 33 24 -31 c29 -38 77 -50 134 -34 46 12 56 23 42
47 -7 13 -4 23 15 40 30 27 33 82 6 106 -23 21 -69 29 -106 17z m42 -68 c2 -7
-6 -12 -17 -12 -21 0 -27 11 -14 24 9 9 26 2 31 -12z m12 -78 c-15 -17 -61
-15 -68 4 -2 8 9 12 38 12 37 0 41 -2 30 -16z"/>
<path d="M5419 3738 c-1 -7 -2 -34 -3 -60 -1 -31 -9 -57 -24 -77 -37 -50 -30
-61 38 -61 41 0 60 4 60 13 3 58 8 119 15 155 l8 42 -47 0 c-29 0 -46 -4 -47
-12z"/>
<path d="M6672 3738 c-28 -14 -58 -62 -67 -108 -9 -50 23 -90 72 -90 23 0 33
-4 31 -12 -6 -18 -52 -22 -79 -7 -19 10 -25 9 -36 -7 -19 -26 -17 -32 17 -49
40 -21 78 -19 130 8 50 25 72 60 90 145 7 30 20 62 31 73 10 10 19 28 19 39 0
19 -6 20 -92 19 -51 0 -103 -5 -116 -11z m83 -58 c8 -25 -15 -74 -38 -78 -23
-5 -24 54 0 80 21 23 30 23 38 -2z"/>
<path d="M9086 3741 c-3 -4 -5 -28 -5 -52 1 -47 -13 -93 -30 -104 -6 -3 -11
-15 -11 -26 0 -18 6 -20 58 -17 l57 3 1 45 c1 25 6 63 13 85 19 69 17 75 -33
75 -25 0 -47 -4 -50 -9z"/>
<path d="M3980 3608 c-23 -25 -25 -39 -8 -56 19 -19 55 -14 72 10 20 28 20 33
0 52 -21 22 -39 20 -64 -6z"/>
<path d="M4094 3605 c-20 -20 -23 -28 -14 -45 13 -25 52 -26 73 -2 20 22 22
43 5 60 -18 18 -36 15 -64 -13z"/>
<path d="M4226 3611 c-24 -27 -17 -65 12 -69 44 -7 79 47 50 76 -18 18 -42 15
-62 -7z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
